import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api'; // Importa l'istanza personalizzata

const apiUrlMenus = '/menus/';

export const updateMenuThunk = createAsyncThunk(
    'menus/updateMenu',
    async ({ menuId, updatedMenu }, { rejectWithValue }) => {
      try {
        const response = await api.put(`${apiUrlMenus}update/${menuId}`, updatedMenu);
        return response.data;

      } catch (error) {
        // Error handling code
        if (error.response) {
          let errorMessage = 'An error occurred';
  
          // Controlla se c'è un errore di validazione con dettagli specifici
          if (error.response.data && error.response.data.error && error.response.data.error.errors) {
            errorMessage = error.response.data.error.message; // Messaggio di errore principale
  
            // Aggiungi ulteriori dettagli dagli errori specifici
            for (const key in error.response.data.error.errors) {
              errorMessage += `\n${error.response.data.error.errors[key].message}`;
            }
          } else if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message; // Messaggio di errore generico
          }
  
          return rejectWithValue(errorMessage);
        }
        // Gestisci errori generici non legati alla risposta dell'API
        return rejectWithValue(error.message || 'An unknown error occurred');
      }
    }
  );
  

  export const getMenuOwner = createAsyncThunk('menus/menuId', async (menuId) => {
    try {
        const response = await api.get(`${apiUrlMenus}${menuId}`, {
        //   headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {

        throw new Error('Error getting owner menu');
    }
})


export const create = createAsyncThunk(
    'menus/create', // Cambia da 'menus/initialization' a 'menus/create'
    async ({ ...menuDetails }, { rejectWithValue }) => {
      try {
        const response = await api.post(`${apiUrlMenus}create`, menuDetails, {
        //   headers: { Authorization: `${token}` },
        });
        return response.data;
      } catch (error) {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.errors
          ) {
            let errorMessage = error.response.data.error.message;
  
            for (const key in error.response.data.error.errors) {
              errorMessage += error.response.data.error.errors[key].message + '\n';
            }
  
            return rejectWithValue(errorMessage);
          } else if (error.response.data && error.response.data.message) {
            return rejectWithValue(error.response.data.message);
          }
        }
        return rejectWithValue(error.message || 'An unknown error occurred');
      }
    }
  );
  
  export const deleteMenu = createAsyncThunk('menus/delete/menuId', async ({menuId}) =>{
    try {
        const response = await api.delete(`${apiUrlMenus}${menuId}`, {
        //   headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})


export const getAllMenusOwner = createAsyncThunk('menus/owner', async (ownerId) => {
    try {
        const response = await api.get(`${apiUrlMenus}owner/${ownerId}`, {
        //   headers: { 'Authorization': `${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error getting owner menus');
    }
})


const menusSlice = createSlice({
    name: 'menus',
    initialState: {
        ownerMenus: null,
        ownerMenusIsLoading: true,
        ownerMenusError: null,
        ownerMenusSuccess: null,
        ownerMenu: null,
        ownerMenuIsLoading: true,
        ownerMenuError: null,
        ownerMenuSuccess: null,
        menuDelete: null,
        isLoadingMenuDelete: true,
        successMenuDelete: null,
        errorMenuDelete: null,
        MenuUpdating: null,
        menuUpdatingIsLoading: null,
        errorMenuUpdating: null,
        successMenuUpdating: null,
        create: null,
        isLoadingCreate: null,
        errorCreate: null,
        successCreate: null
    },
    reducers:{

    },
    extraReducers: (builder) => {
        builder
        .addCase(updateMenuThunk.pending, (state, action) => {
            state.menuUpdatingIsLoading = true;
        })  
        .addCase(updateMenuThunk.rejected, (state, action) => {
            state.errorMenuUpdating = action.payload
            state.menuUpdatingIsLoading = false
        })  
        .addCase(updateMenuThunk.fulfilled, (state, action) => {
            state.menuUpdatingIsLoading = false;
            state.MenuUpdating = action.payload;
            state.successMenuUpdating = action.payload.message
        })  
        .addCase(getAllMenusOwner.pending, (state, action) => {
            state.ownerMenusIsLoading = true
        })
        .addCase(getAllMenusOwner.rejected, (state, action) => {
            state.ownerMenusError = action.payload
            state.ownerMenusIsLoading = false
        })
        .addCase(getAllMenusOwner.fulfilled, (state, action) => {
            state.ownerMenus = action.payload
            state.ownerMenusSuccess = action.payload.message
            state.ownerMenusIsLoading = false
        })
        .addCase(getMenuOwner.pending, (state, action) => {
            state.ownerMenuIsLoading = true
        })
        .addCase(getMenuOwner.rejected, (state, action) => {
            state.ownerMenuError = action.payload
            state.ownerMenuIsLoading = false
        })
        .addCase(getMenuOwner.fulfilled, (state, action) => {
            state.ownerMenu = action.payload
            state.ownerMenuSuccess = action.payload.message
            state.ownerMenuIsLoading = false
        })
        .addCase(create.pending, (state) => {
          state.isLoadingCreate = true;
        })
        .addCase(create.rejected, (state, action) => {
          state.errorCreate = action.payload;
          state.isLoadingCreate = false;
        })
        .addCase(create.fulfilled, (state, action) => {
          state.create = action.payload;
          state.successCreate = action.payload.message;
          state.isLoadingCreate = false;
        })
        .addCase(deleteMenu.pending, (state, action) => {
            state.isLoadingMenuDelete = true
        })
        .addCase(deleteMenu.rejected, (state, action) => {
            state.errorMenuDelete = action.payload
            state.isLoadingMenuDelete = false
        })
        .addCase(deleteMenu.fulfilled, (state, action) => {
            state.menuDelete = action.payload
            state.successMenuDelete = action.payload.message
            state.isLoadingMenuDelete = false
        })
    }
});

export default menusSlice.reducer