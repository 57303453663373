import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/loginsSlice';
import EditOwner from './EditOwner';
import { getOwnerById } from '../redux/ownersSlice';
import defaultAvatar from '../asset/default-avatar.png';

const logoNemu = require('../asset/logo.png');

export function Navbar() {
  const [openNav, setOpenNav] = useState(false);
  const owner = useSelector((state) => state.owners.ownerLogged?.owner);
  const [avatarUrl, setAvatarUrl] = useState(owner?.method !== 'local' ? owner?.avatar : '');
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('userLoggedIn');
  const [showEditOwner, setShowEditOwner] = useState(false);
  const location = useLocation(); // Ottieni la posizione corrente
  const apiImages = process.env.REACT_APP_IMG
  const [imageError, setImageError] = useState(false); 


  useEffect(() => {
    if (owner && owner.method === 'local') {
      const imageUrl = `${apiImages}/${owner.avatar}`;
      setImageError(false);
      setAvatarUrl(imageUrl)
      
    }
  }, [owner, avatarUpdated]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  useEffect(() => {
    setOpenNav(false);
  }, [location]);

  const handleLogOut = () => {
    dispatch(logout());
    navigate('/');
  };

  const openEditOwner = () => {
    setShowEditOwner(true);
  }

  const refreshOwner = () => {
    dispatch(getOwnerById(owner._id));
  }

  const isActive = (path) => {
    return location.pathname === path ? 'green-background-hover' : ''; // Condizione per applicare lo stile selezionato
  }

  const isActiveNav = (path) => {
    return location.pathname === path ? 'bg-gray-100' : ''; // Condizione per applicare lo stile selezionato
  }

  return (
    <>
      <nav className="green-background p-3 w-full fixed top-0 z-50">
        <div className="container flex items-center justify-between h-8">
          <div className="flex items-center">
            <Link to="/">
              <img src={logoNemu} className="h-6" alt="Logo" />
            </Link>
          </div>
          <div className="fixed right-4">
            <div className="hidden lg:flex items-center space-x-6 text-white ">
              <Link to="/" className={`p-2 rounded-lg hover:bg-green-600 transition-colors duration-200 ${isActive('/')}`}>
                Home
              </Link>
              {/* <Link to="/menu-digitale" className={`p-2 rounded-lg hover:bg-green-600 transition-colors duration-200 ${isActive('/menu-digitale')}`}>
                Menu Digitale
              </Link> */}
              {owner && isLoggedIn && <Link to="/dashboard" className={`p-2 rounded-lg hover:bg-green-600 transition-colors duration-200 ${isActive('/dashboard')}`}>
                Configuratore
              </Link>}
              {(!owner || !isLoggedIn) && <Link to="/login" className={`p-2 rounded-lg hover:bg-green-600 transition-colors duration-200 ${isActive('/login')}`}>
                Login
              </Link>}
              {owner && isLoggedIn && <button onClick={handleLogOut} className="p-2 rounded-lg hover:bg-green-600 transition-colors duration-200">Logout</button>}
              {owner && isLoggedIn && !imageError && (
                <img  src={avatarUrl || owner.avatar || defaultAvatar} 
                      onClick={openEditOwner} 
                      alt="Owner Avatar" 
                      className="w-10 h-10 rounded-full object-cover cursor-pointer" 
                      onError={() => setImageError(true)}
                      />
              )}
              {owner && isLoggedIn && imageError && (
                <img  src={defaultAvatar} 
                      onClick={openEditOwner} 
                      alt="Owner Avatar" 
                      className="w-10 h-10 rounded-full object-cover cursor-pointer" 
                      onError={() => setImageError(true)}
                      />
              )}
            </div>
          </div>
          <div className="lg:hidden text-white fixed right-4">
            <button onClick={() => setOpenNav(!openNav)}>
              {openNav ? (
                <XMarkIcon strokeWidth={2} className="h-6 w-6" />
              ) : (
                <Bars3Icon strokeWidth={2} className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {showEditOwner && <EditOwner owner={owner} ownerId={owner._id} setShowEditOwner={setShowEditOwner} showEditOwner={showEditOwner} refreshOwner={refreshOwner} />}
      </nav>
      {openNav && (
        <div className="fixed inset-0 z-40 mt-12">
          <div className="absolute inset-0 bg-black bg-opacity-50"></div> {/* Sfondo scuro traslucido */}
          <div className="absolute top-0 right-0 bg-white w-full h-[350px] shadow-lg z-50 transition-transform duration-300 transform translate-x-0">
            <div className="flex flex-col items-center py-6">
              {owner && isLoggedIn && !imageError && (
                <img
                  src={avatarUrl || owner.avatar || defaultAvatar}
                  onClick={openEditOwner}
                  alt="Owner Avatar"
                  className="w-16 h-16 rounded-full object-cover cursor-pointer mb-4 transition-transform duration-200 hover:scale-105"
                  onError={() => setImageError(true)}
                />
              )}
              {owner && isLoggedIn && imageError && (
                <img
                  src={defaultAvatar}
                  onClick={openEditOwner}
                  alt="Owner Avatar"
                  className="w-16 h-16 rounded-full object-cover cursor-pointer mb-4 transition-transform duration-200 hover:scale-105"
                />
              )}
              <Link
                to="/"
                className={`py-4 w-full text-center text-gray-700 hover:bg-gray-200 transition-colors duration-200 ${isActiveNav('/')}`}
                onClick={() => setOpenNav(false)}
              >
                Home
              </Link>
              {/* <Link
                to="/menu-digitale"
                className={`py-4 w-full text-center text-gray-700 hover:bg-gray-200 transition-colors duration-200 ${isActiveNav('/menu-digitale')}`}
                onClick={() => setOpenNav(false)}
              >
                Menu Digitale
              </Link> */}
              {owner && isLoggedIn && (
                <Link
                  to="/dashboard"
                  className={`py-4 w-full text-center text-gray-700 hover:bg-gray-200 transition-colors duration-200 ${isActiveNav('/dashboard')}`}
                  onClick={() => setOpenNav(false)}
                >
                  Configuratore
                </Link>
              )}
              {!(owner && isLoggedIn) && (
                <Link
                  to="/login"
                  className={`py-4 w-full text-center text-gray-700 hover:bg-gray-200 transition-colors duration-200 ${isActiveNav('/login')}`}
                  onClick={() => setOpenNav(false)}
                >
                  Login
                </Link>
              )}
              {owner && isLoggedIn && (
                <button
                  onClick={handleLogOut}
                  className="py-4 w-full text-center text-red-600 hover:bg-gray-200 transition-colors duration-200"
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
