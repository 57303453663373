import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuThunk } from '../redux/menusSlice';
import { getIngredients, getAllergens } from '../redux/ingredientsSlice';
import CreateIngredient from './CreateIngredient';
import Input from './modal/Input';
import Toggle from './modal/Toggle';
import Modal from './modal/Modal';
import Translations from './modal/Translations';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import { FiPlus } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';

const updateMenu = require('./logic/updateMenu.js');

const CreateMenuItem = ({
    owner,
    menu,
    menuId,
    categoryId,
    showCreateMenuItem,
    setShowCreateMenuItem,
    refreshAllMenusOwner,
    refreshMenuOwner,
    menuDefaultLanguage
}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const isLoading = useSelector((state) => state.menus.menuUpdatingIsLoading);
    const errorMessage = useSelector((state) => state.menus.errorMenuUpdating);
    const successMessage = useSelector((state) => state.menus.successMenuUpdating);
    const ingredients = useSelector((state) => state.ingredients.ingredientsOwner);
    const translatedIngredients = useSelector((state) => state.translatedIngredients?.translatedIngredientsOwner?.ingredientTranslations);
    const [showCreateIngredient, setShowCreateIngredient] = useState(false);
    const [forceHideSaveButton, setForceHideSaveButton] = useState(false);
    const categoryIsComplete = menu.menu.menuCategory.find((category) => category._id === categoryId).isComplete;
    const [outputTranslations, setOutputTranslations] = useState([]);
    const defaultLanguage = menu.defaultLanguage;
    const [formattedTranslations, setFormattedTranslations] = useState([]);
    const [allergens, setAllergens] = useState([]);

    // Added state variables
    const [nameError, setNameError] = useState(false); // Tracks validation error for "Nome"
    const [ingredientsError, setIngredientsError] = useState(false); // Tracks validation error for "Ingredienti"
    const [uploadMessage, setUploadMessage] = useState(false); // Handles upload messages
    const [uploadErrorState, setUploadErrorState] = useState(false); // Handles upload errors

    const [itemDetails, setItemDetails] = useState({
        foodOrBeverage: 'food',
        translations: menu.menu.translations
            .map(lang => ({
                language: lang.language,
                name: '',
                description: ''
            })),
        color: '',
        image: '',
        price: '',
        isActive: true,
        isVegetarian: false,
        isVegan: false,
        isMeat: false,
        isFish: false,
        ingredients: [],
        allergens: []
    });

    useEffect(() => {
        if (outputTranslations && outputTranslations.length > 0) {
            const formattedTranslations = outputTranslations.map((lang) => ({
                language: lang.language,
                name: lang.name,
                description: lang.description,
            }));
            setItemDetails((prevItemDetails) => ({
                ...prevItemDetails,
                translations: formattedTranslations || [],
            }));
        }
    }, [outputTranslations]);


    const dispatch = useDispatch();

    const handleShowCreateIngredient = () => {
        setShowCreateIngredient(true);
    };

    useEffect(() => {
        const fetchAllergens = async () => {
            try {
                // Dispatch the getAllergens action to the Redux store
                const resultAction = await dispatch(getAllergens());

                // Check if the call was successful
                if (getAllergens.fulfilled.match(resultAction)) {
                    const allergeni = resultAction.payload; // Allergen data from the response
                    setAllergens(allergeni);
                } else {
                    console.error('Errore nella chiamata:', resultAction.error.message);
                }
            } catch (error) {
                console.error('Errore durante la chiamata a getAllergens:', error);
            }
        };
        fetchAllergens();
    }, [dispatch]);

    const allergenOptions = allergens.map(allergen => ({
        value: allergen._id,
        label: allergen.name
    }));

    const [uploadedFileName, setUploadedFileName] = useState(false);
    const [inputImage, setInputImage] = useState(''); // Define inputImage state

    useEffect(() => {
        if (uploadedFileName) {
            setItemDetails(prevState => ({
                ...prevState,
                image: uploadedFileName
            }));
            setInputImage(uploadedFileName); // Update inputImage
        }
    }, [uploadedFileName]);

    const handleRemoveImage = () => {
        setItemDetails(prevState => ({
            ...prevState,
            image: ''
        }));

        setUploadMessage(false);
        setUploadErrorState(false);
        document.getElementById('fileInput').value = ''; // Reset file input
        setInputImage(''); // Reset inputImage
    };

    useEffect(() => {
        dispatch(getIngredients());
    }, [dispatch]);

    const createIdValueObject = (ingredients, defaultLanguage) => {
        if (!ingredients || ingredients.length === 0) {
            return [];
        }
        return ingredients.reduce((acc, ingredient) => {
            const translation = ingredient.translations.find(t => t.language === defaultLanguage);
            if (translation) {
                acc.push({ value: ingredient._id, label: translation.translatedName });
            }
            return acc;
        }, []);
    };

    const ingredientsOptions = createIdValueObject(ingredients, defaultLanguage);

    const selectIngredient = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);
        if (selectedIds.length > 0) {
            setIngredientsError(false);
            setError(false);
        }
        setItemDetails(prev => ({
            ...prev,
            ingredients: selectedIds
        }));
    };

    const handleAllergenChange = (selectedOptions) => {
        const selectedAllergenValues = selectedOptions.map(option => option.value);
        setItemDetails(prev => ({
            ...prev,
            allergens: selectedAllergenValues
        }));
    };

    const [color, setColor] = useState('');

    const handleColorChange = (color) => {
        setColor(color.hex);
        setItemDetails(prevState => ({
            ...prevState,
            color: color.hex
        }));
    };

    const deleteColor = () => {
        setColor('');
        setItemDetails(prevState => ({
            ...prevState,
            color: ''
        }));
    };

    const closeCreateMenuItem = () => {
        setShowCreateMenuItem(false);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        if (name === 'name' || name === 'description') {
            if (name === 'name' && value.trim() !== '') {
                setNameError(false);
                setError(false);
            }
            setItemDetails(prevState => ({
                ...prevState,
                translations: prevState.translations.map(translation =>
                    translation.language === defaultLanguage
                        ? { ...translation, [name]: inputValue } // Update the 'name' or 'description' field for the current language
                        : translation
                )
            }));
        } else {
            setItemDetails(prevState => ({
                ...prevState,
                [name]: inputValue
            }));
        }
    };

    const handleToggleChange = (name) => {
        setItemDetails((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    // Removed handleDeleteItem function to resolve ESLint error
    // If you need to handle deletion, ensure that 'getHandleDeleteMenuCategory' is defined or passed as a prop

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation
        const name = itemDetails.translations.find(t => t.language === defaultLanguage)?.name.trim();
        const isFood = itemDetails.foodOrBeverage === 'food';
        const hasIngredients = itemDetails.ingredients.length > 0;

        let hasError = false;

        if (!name) {
            setNameError(true);
            hasError = true;
        }

        if (isFood && !hasIngredients) {
            setIngredientsError(true);
            hasError = true;
        }

        if (hasError) {
            setError(true);
            return;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);

        const updatedMenu = updateMenu(menu, itemDetails, 'menuItemCreate', categoryId);

        try {
            const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
            if (updateMenuThunk.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshMenuOwner(menuId);
                closeCreateMenuItem();
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Error updating the menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    return (

        <>
            {showCreateMenuItem &&

                <Modal
                    handleSubmit={handleSubmit}
                    closeFunction={closeCreateMenuItem}
                    title="Crea Piatto"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                // Removed deleteFunction prop
                >

                    {/* "Nome" Input with Validation */}
                    <Input
                        description={itemDetails?.translations?.find(t => t.language === defaultLanguage)?.name || ''}
                        name='name'
                        onChange={handleInputChange}
                        tipo="InputText"
                        hasError={nameError} // Pass the error state
                        ariaDescribedby="name-error" // Enhance accessibility
                        maxLength={100} // Limite di caratteri aggiunto
                    >
                        <div className="text-2xl">
                            Nome <span className="text-red-500">*</span> {/* Indicate required */}
                        </div>
                    </Input>
                    {nameError && <span id="name-error" className="text-red-500">Il nome del piatto è obbligatorio.</span>}

                    {/* "Descrizione" Input */}
                    <Input
                        description={itemDetails?.translations?.find(t => t.language === defaultLanguage)?.description || ''}
                        name='description'
                        onChange={handleInputChange}
                        tipo="InputText"
                        maxLength={500} // Limite di caratteri aggiunto

                    >
                        <div className="text-2xl">Descrizione</div>
                    </Input>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Traduzioni</div>

                    <Translations
                        defaultLanguage={defaultLanguage}
                        inputTranslations={itemDetails.translations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    />

                    <hr className="border-gray-400 mt-4"></hr>
                    <div className="text-2xl font-bold mt-4">Ingredienti e allergeni</div>

                    <div className='flex flex-col items-start'>

                        <div className='flex flex-col md:flex-row items-center justify-center gap-2 my-1 mt-8'>
                            <span className='font-bold'>Cibo o Bevanda</span>
                            <select
                                className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                name="foodOrBeverage"
                                value={itemDetails.foodOrBeverage}
                                onChange={handleInputChange}
                            >
                                <option value="food">Cibo</option>
                                <option value="beverage">Bevanda</option>
                            </select>
                        </div>
                    </div>

                    <>
                        <div className='flex flex-col mt-4 '>
                            {itemDetails.foodOrBeverage === 'food' &&
                                <h3 className="font-bold col-span-2 text-start">

                                    Ingredienti <span className="text-red-500">*</span>
                                </h3>
                            }
                            {itemDetails.foodOrBeverage != 'food' &&
                                <h3 className="font-bold col-span-2 text-start">

                                    Ingredienti
                                </h3>
                            }

                            <div className='grid grid-cols-12 gap-2 items-center'>
                                {/* Updated "Ingredienti" Label with Red Asterisk */}

                                {/* Wrapped Select and "+" Button in a Flex Container */}
                                <div className='col-span-12 flex items-center'>
                                    <Select
                                        isMulti
                                        name="ingredients"
                                        options={ingredientsOptions} // Usa le opzioni mappate
                                        className={`basic-multi-select flex-grow ${ingredientsError ? 'border-red-500' : ''}`} // Apply border if error
                                        classNamePrefix="select"
                                        value={ingredientsOptions?.filter(option =>
                                            itemDetails?.ingredients.includes(option.value)
                                        )} // Mappa i valori selezionati
                                        onChange={selectIngredient}
                                        placeholder="Cerca ingredienti..."
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderColor: ingredientsError ? 'red' : provided.borderColor,
                                                '&:hover': {
                                                    borderColor: ingredientsError ? 'red' : provided.borderColor
                                                }
                                            })
                                        }}
                                    />
                                    {/* "+" Button aligned inline with Select */}
                                    <button
                                        className="green-background hover:bg-green-600 text-center text-white h-9 w-9 ml-2 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowCreateIngredient();
                                        }}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Personalizza"
                                    >
                                        <FiPlus className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                            {/* Error Message Positioned Below Ingredients */}
                            {ingredientsError && (
                                <span className="text-red-500 mt-1">Seleziona almeno un ingrediente.</span>
                            )}
                        </div>
                    </>
                    <h3 className='font-bold col-span-2 text-start mt-4'>Allergeni</h3>

                    <div className='grid grid-cols-12 md:flex-row justify-between gap-2 items-center my-1  w-full'>
                        <Select
                            isMulti
                            name="allergens"
                            options={allergenOptions} // Usa le opzioni mappate da `allergens`
                            className="basic-multi-select w-full col-span-12"
                            classNamePrefix="select"
                            value={allergenOptions.filter(option =>
                                itemDetails.allergens.includes(option.value))} // Mappa i valori selezionati
                            onChange={handleAllergenChange} // Gestisce il cambiamento degli allergeni
                            placeholder="Seleziona allergeni..."
                        />
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Info Piatto</div>

                    <div className='flex flex-col gap-2 my-1'>
                        <div className='flex flex-row items-center flex-wrap text-xl '>

                            <div className="mt-6 flex flex-col justify-center my-1">
                                <div className="flex flex-row items-center flex-wrap text-base gap-2">
                                    <div className="flex items-center mr-2">
                                        <Toggle
                                            isOn={itemDetails.isActive}
                                            handleToggle={() => handleToggleChange('isActive')}
                                        />
                                        <span className="ml-2 text-gray-700">Attivo</span>
                                    </div>
                                    {itemDetails.foodOrBeverage === 'food' &&

                                        <>
                                            <div className="flex items-center mr-2">
                                                <Toggle
                                                    isOn={itemDetails.isVegetarian}
                                                    handleToggle={() => handleToggleChange('isVegetarian')}
                                                />
                                                <span className="ml-2 text-gray-700">Vegetariano</span>
                                            </div>
                                            <div className="flex items-center mr-2">
                                                <Toggle
                                                    isOn={itemDetails.isVegan}
                                                    handleToggle={() => handleToggleChange('isVegan')}
                                                />
                                                <span className="ml-2 text-gray-700">Vegano</span>
                                            </div>
                                            <div className="flex items-center mr-2">
                                                <Toggle
                                                    isOn={itemDetails.isMeat}
                                                    handleToggle={() => handleToggleChange('isMeat')}
                                                />
                                                <span className="ml-2 text-gray-700">Carne</span>
                                            </div>
                                            <div className="flex items-center mr-2">
                                                <Toggle
                                                    isOn={itemDetails.isFish}
                                                    handleToggle={() => handleToggleChange('isFish')}
                                                />
                                                <span className="ml-2 text-gray-700">Pesce</span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="flex flex-row item-center mt-4">
                        <div className='flex flex-col items-start'>
                            {!categoryIsComplete && (
                                <div className='flex flex-col md:flex-row justify-between gap-2 items-center my-1'>
                                    <h3 className='font-bold'>Prezzo</h3>
                                    <div className=' p-2 w-40 flex flex-row justify-between items-center'>
                                        <input
                                            className='border rounded-lg border-gray-400 p-2 bg-transparent'
                                            value={itemDetails.price}
                                            name='price'
                                            onChange={handleInputChange}
                                            type='number'
                                            min="0"
                                            step="any"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Layout</div>

                    <ColorSection
                        handleColorChange={handleColorChange}
                        deleteColor={deleteColor}
                        color={color}
                    />

                    {/* ImageSection with Upload States */}
                    <ImageSection
                        inputImage={inputImage} // Pass the defined state
                        handleRemoveImage={handleRemoveImage}
                        setUploadedFileName={setUploadedFileName}
                        aspect={2 / 2}
                        maxSizeMB={0.1}
                        maxWidthOrHeight={1200}
                        uploadMessage={uploadMessage}
                        setUploadMessage={setUploadMessage}
                        uploadError={uploadErrorState}
                        setUploadError={setUploadErrorState}
                    />



                    {showCreateIngredient && (
                        <CreateIngredient
                            showIngredientCreation={showCreateIngredient}
                            setShowIngredientCreation={setShowCreateIngredient}
                            menuDefaultLanguage={menuDefaultLanguage}
                            ingredients={ingredients}
                            translatedIngredients={translatedIngredients}
                        />
                    )}

                    {/* Feedback Messages */}
                    <div className='flex flex-row justify-center gap-2'>
                        {/* You can add additional buttons or elements here if needed */}
                    </div>
                    {isLoading && loading &&
                        <div className='flex flex-row justify-center gap-2 pt-2'><div className='custom-loader'></div></div>
                    }
                    {success && successMessage &&
                        <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs'>{successMessage}</div>
                    }
                    {error && errorMessage &&
                        <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs'>{errorMessage}</div>
                    }
                </Modal>
            }
            <Tooltip id="tooltip" className="z-50" />

        </>
    );

}

export default CreateMenuItem;
