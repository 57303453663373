// MenuConfiguratorContainer.jsx
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLeaf,
  faFish,
  faSeedling,
  faDrumstickBite,
  faCheckCircle,
  faTimesCircle,
  faTrash,
  faBars,
  faClone
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMenusOwner,
  getMenuOwner,
  updateMenuThunk,
  create,
  deleteMenu
} from '../redux/menusSlice';
import CreateMenu from './CreateMenu';
import EditMenu from './EditMenu';
import CreateMenuCategory from './CreateMenuCategory';
import EditMenuCategory from './EditMenuCategory';
import CreateMenuItem from './CreateMenuItem';
import EditMenuItem from './EditMenuItem';
import MyIframe from './myIframe';
import QRCodeModal from './QRCodeModal';
import { SlArrowRight, SlArrowDown } from 'react-icons/sl';
import Button from './modal/Buttons';
import { AiOutlineQrcode, AiOutlinePicture, AiOutlineImport, AiOutlinePlus, AiOutlineExport } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import DragAndDropModal from './DradAndDropModal';
import { FaCog } from 'react-icons/fa';
import { BsThreeDotsVertical } from 'react-icons/bs';
import AdvancedOptions from './AdvancedOptions';

const updateMenu = require('./logic/updateMenu.js');

const MenuConfiguratorContainer = () => {
  const dispatch = useDispatch();
  const [selectedMenuId, setSelectedMenuId] = useState('');
  const [selectedMenuDetails, setSelectedMenuDetails] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [showCreateMenu, setShowCreateMenu] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [showCreateMenuCategory, setShowCreateMenuCategory] = useState(false);
  const [showEditMenuCategory, setShowEditMenuCategory] = useState(false);
  const [showCreateMenuItem, setShowCreateMenuItem] = useState(false);
  const [showEditMenuItem, setShowEditMenuItem] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [selectedEditItemId, setSelectedEditItemId] = useState(null);
  const [selectedEditCategoryId, setSelectedEditCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const isLoadingMenuCategoryDelete = useSelector((state) => state.menus.isLoadingMenuCategoryDelete);
  const isLoadingMenuDelete = useSelector((state) => state.menus.isLoadingMenuDelete);
  const isLoadingMenuItemDelete = useSelector((state) => state.menus.isLoadingMenuItemDelete);

  const iFrameBaseUrl = process.env.REACT_APP_IFRAME;
  const [iframeUrl, setIframeUrl] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isDragAndDropOpen, setIsDragAndDropOpen] = useState(false);
  const [openActions, setOpenActions] = useState({ type: null, id: null });

  const owner = useSelector((state) => state.owners.ownerLogged?.owner);

  const handleOpenEditMenuItem = (itemId, categoryId) => {
    setSelectedEditItemId(itemId);
    setSelectedEditCategoryId(categoryId);
    setShowEditMenuItem(true);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  useEffect(() => {
    if (owner && owner._id) {
      dispatch(getAllMenusOwner(owner._id));
      setOwnerId(owner._id);
      setIframeUrl(`${iFrameBaseUrl}/menu/${owner._id}`)
    }
  }, [dispatch, owner]);

  const menus = useSelector((state) => state.menus.ownerMenus?.menus);
  const menu = useSelector((state) => state.menus.ownerMenu?.menu);

  // Funzione per esportare il JSON come file
  const handleExportMenu = () => {
    if (menu) {
      const fileName = `${menu.menu.translations.find(t => t.language === 'ita')?.name || 'Unnamed Menu'}.json`; // Nome file
      const jsonStr = JSON.stringify(menu, null, 2); // Formattato con indentazione
      const blob = new Blob([jsonStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url); // Rimuovi l'URL dopo il download
    } else {
      alert("Nessun menu selezionato per l'esportazione.");
    }
  };

  const handleMenuChange = (e) => {
    const menuId = e.target.value;
    setSelectedMenuId(menuId);
    dispatch(getMenuOwner(menuId)).then((response) => {
      if (response.payload) {
        setSelectedMenuDetails(response.payload);
      } else {
        setSelectedMenuDetails(null); // Se non c'è nessun menu trovato
      }
    });
  };

  const refreshMenuOwner = () => {
    if (menu && menu._id) { // Verifica che menu e menu._id siano definiti
      dispatch(getMenuOwner(menu._id));
    }
  };

  const refreshAllMenusOwner = (ownerId) => {
    dispatch(getAllMenusOwner(ownerId));
  };

  const handleShowEditMenu = () => {
    setShowEditMenu(true);
  };

  const handleShowCreateMenu = () => {
    setShowCreateMenu(true);
  };

  const handleShowAdvancedOptions = () => {
    setShowAdvancedOptions(true);
  };

  const handleOpenCreateMenuCategory = () => {
    setShowCreateMenuCategory(true);
  };

  const handleOpenShowEditMenuCategory = (categoryId) => {
    setSelectedEditCategoryId(categoryId);
    setShowEditMenuCategory(true);
  };

  const handleOpenShowCreateMenuItem = (categoryId) => {
    setSelectedEditCategoryId(categoryId);
    setShowCreateMenuItem(true);
  };

  const getHandleDeleteMenu = (menuId) => async () => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo menu?");
    if (confirmDelete) {
      await handleDeleteMenu(menuId);
      setShowEditMenu(false);
      setSelectedMenuId('');
    }
  };

  const handleDeleteMenu = async (menuId) => {
    setLoading(true);
    const response = await dispatch(deleteMenu({ menuId }));
    if (deleteMenu.fulfilled.match(response)) {
      setSelectedMenuId('');
      setSelectedMenuDetails(null);
      await refreshAllMenusOwner(owner._id);
    }
    setLoading(false);
  };


  // Funzione diretta di eliminazione della categoria
  const handleDeleteMenuCategory = async (menuId, categoryId) => {

    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questa categoria?");
    if (confirmDelete) {
      setLoading(true);
      const updatedMenu = updateMenu(menu, null, 'categoryDelete', categoryId);
      try {
        const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
        if (updateMenuThunk.fulfilled.match(response)) {
          await refreshMenuOwner(menuId);
        }
      } catch (error) {
        console.error('Error deleting the category:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Funzione diretta di eliminazione di un menu item
  const handleDeleteMenuItem = async (menuId, categoryId, itemId) => {
    const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo piatto?");
    if (confirmDelete) {
      setLoading(true);
      const updatedMenu = updateMenu(menu, null, 'menuItemDelete', categoryId, itemId);
      try {
        const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
        if (updateMenuThunk.fulfilled.match(response)) {
          await refreshMenuOwner(menuId);
        }
      } catch (error) {
        console.error('Error deleting the dish:', error);
      } finally {
        setLoading(false);
      }
    }
  };


  const handleIframeUrlChange = (url) => {
    setIframeUrl(url);
  };

  const openQRCodeModal = () => {
    setIsQRCodeModalOpen(true);
  };

  const closeQRCodeModal = () => {
    setIsQRCodeModalOpen(false);
  };

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const openDragAndDropModal = () => {
    setIsDragAndDropOpen(true);
  };

  const closeDragAndDropModal = () => {
    setIsDragAndDropOpen(false);
  };

  const closeShowAdvancedOptions = () => {
    setShowAdvancedOptions(false);
  };

  const handleDuplicateMenu = async () => {
    if (!menu) {
      console.error("Menu non definito.");
      return;
    }
    const updatedMenu = updateMenu(menu, null, 'duplicateMenu', null, null);

    try {
      const response = await dispatch(create(updatedMenu));
      if (create.fulfilled.match(response)) {
        setSelectedMenuDetails(updatedMenu);
        refreshAllMenusOwner(menu.owner);
      }
    } catch (error) {
      console.error('Error duplicating the menu:', error);
    }
  };

  const handleDuplicateCategory = async (categoryId) => {
    if (!menu || !menu._id) {
      console.error("Menu non definito.");
      return;
    }
    const updatedMenu = updateMenu(menu, null, 'duplicateCategory', categoryId, null);

    try {
      const response = await dispatch(updateMenuThunk({ menuId: menu._id, updatedMenu }));
      if (updateMenuThunk.fulfilled.match(response)) {
        setSelectedMenuDetails(updatedMenu);
        refreshMenuOwner();
      }
    } catch (error) {
      console.error('Error duplicating the category:', error);
    }
  };



  const handleDuplicateMenuItem = async (categoryId, itemId) => {
    if (!menu || !menu._id) {
      console.error("Menu non definito.");
      return;
    }
    const updatedMenu = updateMenu(menu, null, 'duplicateItem', categoryId, itemId);

    try {
      const response = await dispatch(updateMenuThunk({ menuId: menu._id, updatedMenu }));
      if (updateMenuThunk.fulfilled.match(response)) {
        setSelectedMenuDetails(updatedMenu);
        refreshMenuOwner();
      }
    } catch (error) {
      console.error('Error duplicating the menu item:', error);
    }
  };

  const toggleActions = (type, id) => {
    setOpenActions((prev) => (prev.type === type && prev.id === id ? { type: null, id: null } : { type, id }));
  };

  const closeActions = () => {
    setOpenActions({ type: null, id: null });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-20 ml-4 mr-4" onClick={closeActions}>


      <div className="col-span-1 lg:col-span-2">
        {/* Header con selezione menu e pulsanti di azione */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
          {/* Gruppo di elementi a sinistra */}
          <div className="flex flex-col items-center sm:flex-row sm:justify-between sm:items-center gap-2 mb-8">
            {/* Menu Selection */}
            <div className="flex flex-col items-center sm:flex-row sm:items-center sm:gap-2 w-full sm:w-auto text-center sm:text-left gap-2">
              {menus && menus.length > 0 ? (
                <>
                  <select
                    className="p-2 border border-green-800 bg-gray-50 rounded-lg font-primary text-sm w-56"
                    value={selectedMenuId}
                    onChange={handleMenuChange}
                  >
                    <option value="">Seleziona un menu</option>
                    {menus.map((menuItem) => (
                      <option key={menuItem._id} value={menuItem._id}>
                        {menuItem.menu.translations.find(t => t.language === 'ita')?.name || 'Unnamed Menu'}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <span className="font-primary text-sm text-center text-black">No menus found</span>
              )}
            </div>

            {/* Buttons */}
            <div className="flex flex-wrap items-center justify-center gap-2">
              <Button
                type="green"
                onClick={handleShowCreateMenu}
                data-tooltip-id="tooltip"
                data-tooltip-content="Nuovo Menu"
              >
                Nuovo Menu
              </Button>

              <button
                onClick={openQRCodeModal}
                className="green-background hover:bg-green-600 text-white h-9 p-2 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
              >
                <AiOutlineQrcode className="w-6 h-6 m-1" />
                QRCode
              </button>

              <button
                onClick={handleShowAdvancedOptions}
                className="green-background hover:bg-green-600 text-white h-9 p-2 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
              >
                <FaCog className="w-5 h-5 m-1" />
                Avanzate
              </button>
            </div>
          </div>

          {/* Modali per creare e modificare menu */}
          {showCreateMenu && (
            <CreateMenu
              owner={owner}
              showCreateMenu={showCreateMenu}
              setShowCreateMenu={setShowCreateMenu}
              refreshAllMenusOwner={refreshAllMenusOwner}
              menu={menu}
            />
          )}

          {showAdvancedOptions &&
            <AdvancedOptions
              closeShowAdvancedOptions={closeShowAdvancedOptions}
              owner={owner}
              refreshAllMenusOwner={refreshAllMenusOwner}
              refreshMenuOwner={refreshMenuOwner}
            />
          }

          {showEditMenu && menu && (
            <EditMenu
              owner={owner}
              showEditMenu={showEditMenu}
              setShowEditMenu={setShowEditMenu}
              menu={menu}
              refreshMenuOwner={refreshMenuOwner}
              refreshAllMenusOwner={refreshAllMenusOwner}
              setSelectedMenuId={setSelectedMenuId}
              getHandleDeleteMenu={getHandleDeleteMenu}
            />
          )}

        </div>

        {selectedMenuId && menu &&
          <div className='relative flex flex-col my-4 border border-gray-400 rounded-xl'>

            <div className="flex flex-row justify-between">
              <div className='truncate text-black text-4xl font-bold text-start my-2 ml-4'>
                {menu.menu.translations.find(t => t.language === 'ita')?.name}
              </div>
              <div className="relative">
                <button
                  className="text-black h-8 w-8 rounded-full flex items-center justify-center mt-5 mr-4"
                  onClick={(e) => { e.stopPropagation(); toggleActions('menu', menu._id); }}
                  data-tooltip-id="tooltip"
                  data-tooltip-content="Azioni"
                >
                  <BsThreeDotsVertical className="w-5 h-5 " />
                </button>
                {openActions.type === 'menu' && openActions.id === menu._id && (
                  <div className="absolute right-0 text-base mt-2 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-20">
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={(e) => { e.stopPropagation(); handleShowEditMenu(); closeActions(); }}
                    >
                      <FiEdit2 className="inline mr-2" /> Modifica
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={(e) => { e.stopPropagation(); handleDuplicateMenu(); closeActions(); }}
                      disabled={!menu}
                    >
                      <FontAwesomeIcon icon={faClone} className="inline mr-2" /> Duplica
                    </button>

                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={(e) => { e.stopPropagation(); openDragAndDropModal(); closeActions(); }}
                    >
                      <FontAwesomeIcon icon={faBars} className="inline mr-2" /> Riordina
                    </button>

                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={(e) => { e.stopPropagation(); handleExportMenu(); closeActions(); }}
                    >
                      <AiOutlineExport className="inline mr-2" /> Esporta
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                      onClick={(e) => { e.stopPropagation(); getHandleDeleteMenu(menu._id)(); closeActions(); }}
                      disabled={!menu}
                    >
                      <FontAwesomeIcon icon={faTrash} className="inline mr-2" /> Elimina
                    </button>
                  </div>
                )}
              </div>

              <DragAndDropModal
                isOpen={isDragAndDropOpen}
                onRequestClose={closeDragAndDropModal}
                menu={menu}
                owner={owner}
                refreshMenuOwner={refreshMenuOwner}
                refreshAllMenusOwner={refreshAllMenusOwner}
              />
            </div>
            <hr className="border-gray-400 mt-4 mx-4"></hr>

            <div className='px-4'>
              <div className='w-48 mt-8' >
                <Button type="green" onClick={handleOpenCreateMenuCategory} >
                  Nuova Categoria
                </Button>
              </div>
              {menu && menu.menu && menu.menu.menuCategory && menu.menu.menuCategory.length > 0 ? (
                <div className="mt-4">
                  {menu.menu.menuCategory.map((category) => (
                    <div key={category._id} className="py-2 rounded-lg">
                      <div className="flex flex-row justify-center items-center">
                        <div
                          className="flex flex-row justify-between items-center w-full border border-gray-300 p-2 bg-gray-200 text-black text-lg rounded-lg cursor-pointer"
                          onClick={() => toggleCategory(category._id)}
                        >
                          <div className="flex items-center">
                            <button
                              className="pr-4"
                              data-tooltip-id="tooltip"
                              data-tooltip-content="Apri / Chiudi"
                              onClick={(e) => { e.stopPropagation(); toggleCategory(category._id); }}
                            >
                              {expandedCategory === category._id ? <SlArrowDown /> : <SlArrowRight />}
                            </button>
                            <div className="flex flex-col">
                              <h3 className="font-primary">
                                {category.translations.find(t => t.language === 'ita')?.name || 'Unnamed Category'}
                                <span className="italic text-sm"> ({category.menuItems.length})</span>
                              </h3>
                              <span className="flex justify-start font-primary italic text-sm">
                                {category.translations.find(t => t.language === 'ita')?.description || 'Unnamed Category Description'}
                              </span>
                            </div>

                          </div>
                          <div className="flex flex-row items-center">
                          {category.isComplete && category.price && (
                              <span className="flex justify-start  text-xl font-bold mr-4">{category.price}€</span>
                            )}
                            <div className="relative">
                              <button
                                className=" green-background-text h-8 w-8  flex items-center justify-center  transition transform hover:scale-105 focus:outline-none"
                                onClick={(e) => { e.stopPropagation(); toggleActions('category', category._id); }}
                                data-tooltip-id="tooltip"
                                data-tooltip-content="Azioni"
                              >
                                <BsThreeDotsVertical className="w-5 h-5" />
                              </button>
                              {openActions.type === 'category' && openActions.id === category._id && (
                                <div className="absolute text-base right-0 mt-2 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-20">
                                  <button
                                    className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                    onClick={(e) => { e.stopPropagation(); handleOpenShowEditMenuCategory(category._id); closeActions(); }}
                                  >
                                    <FiEdit2 className="inline mr-2" /> Modifica
                                  </button>
                                  <button
                                    className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                    onClick={(e) => { e.stopPropagation(); handleDuplicateCategory(category._id); closeActions(); }}
                                    disabled={!menu}
                                  >
                                    <FontAwesomeIcon icon={faClone} className="inline mr-2" /> Duplica
                                  </button>
                                  <button
                                    className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                                    onClick={(e) => { e.stopPropagation(); handleDeleteMenuCategory(menu._id, category._id); closeActions(); }}
                                    disabled={!menu}
                                  >
                                    <FontAwesomeIcon icon={faTrash} className="inline mr-2" /> Elimina
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Modale di EditMenuCategory */}
                      {showEditMenuCategory && selectedEditCategoryId === category._id && (
                        <EditMenuCategory
                          key={selectedEditCategoryId}
                          owner={owner}
                          menu={menu}
                          menuId={menu._id}
                          categoryId={selectedEditCategoryId}
                          showEditMenuCategory={showEditMenuCategory}
                          setShowEditMenuCategory={setShowEditMenuCategory}
                          refreshAllMenusOwner={refreshAllMenusOwner}
                          refreshMenuOwner={refreshMenuOwner}
                          getHandleDeleteMenuCategory={handleDeleteMenuCategory}
                          menuDefaultLanguage={menu.defaultLanguage}
                        />
                      )}

                      {/* Sezione espansa con i menu items */}
                      {expandedCategory === category._id && (
                        <div className="flex flex-col items-start mt-2 border border-gray-200 bg-gray-50 p-4 rounded-xl">
                          <div className="mb-2 justify-start">
                            <Button type="green" onClick={() => handleOpenShowCreateMenuItem(category._id)}>Nuovo Piatto</Button>
                          </div>
                          {category.menuItems && category.menuItems.length > 0 ? (
                            <div className="grid grid-cols-1 gap-2 w-full">
                              {category.menuItems.map((item) => (
                                <div key={item._id} className="mt-1 p-2 border border-green-200 bg-green-100 rounded-lg">
                                  <div className="flex flex-row items-center justify-between w-full">
                                    <div className="flex flex-row  items-center overflow-hidden">
                                      <div className="flex  ">
                                        {/* Pulsante Modifica */}
                                        <button
                                          className=" green-background-text h-6 w-6 rounded-full flex items-center justify-center transition transform hover:scale-105 focus:outline-none "
                                          onClick={(e) => { e.stopPropagation(); toggleActions('item', item._id); }}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Azioni"
                                        >
                                          <BsThreeDotsVertical className="w-4 h-4" />
                                        </button>
                                      </div>

                                      <span className="text-sm font-primary truncate mx-2 w-full">
                                        {item.translations.find(t => t.language === 'ita')?.name || 'Unnamed Piatto'}
                                      </span>
                                    </div>

                                    {/* Spazio vuoto per allineamento */}
                                    <div></div>

                                    <div className="flex flex-row justify-end items-center gap-2">
                                      <div className="hidden md:flex flex-row justify-end items-center gap-2">
                                        <FontAwesomeIcon
                                          icon={item.isActive ? faCheckCircle : faTimesCircle}
                                          className={item.isActive ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content={item.isActive ? 'Attivo' : 'Inattivo'}
                                        />
                                        <div className="border-r-2 border-gray-400 h-6"></div> {/* Divider */}

                                        <div className="w-4 h-4 rounded-full" style={{ backgroundColor: item.color || '#d3d3d3' }}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Colore"
                                        ></div>

                                        <AiOutlinePicture
                                          className={item.image && item.image !== "false" ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          style={{ minWidth: '24px' }}
                                          data-tooltip-content="Immagine"
                                        />
                                        <div className="border-r-2 border-gray-400 h-6"></div> {/* Divider */}

                                        <FontAwesomeIcon
                                          icon={faSeedling}
                                          className={item.isVegetarian ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Vegetariano"
                                        />
                                        <FontAwesomeIcon
                                          icon={faLeaf}
                                          className={item.isVegan ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Vegano"
                                        />
                                        <FontAwesomeIcon
                                          icon={faDrumstickBite}
                                          className={item.isMeat ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Carne"
                                        />
                                        <FontAwesomeIcon
                                          icon={faFish}
                                          className={item.isFish ? 'green-background-text' : 'text-gray-300'}
                                          data-tooltip-id="tooltip"
                                          data-tooltip-content="Pesce"
                                        />
                                      </div>
                                      <div className="flex flex-row justify-end items-center w-full">
                                     {!category.isComplete &&
                                        <span className="text-sm font-primary font-bold green-background-text ml-4" style={{ width: '50px', textAlign: 'right' }}>
                                          {parseFloat(item.price).toFixed(1)}€
                                        </span>}
                                      </div>
                                    </div>


                                    {/* Modale di EditMenuItem */}
                                    {showEditMenuItem && selectedEditItemId === item._id && (
                                      <EditMenuItem
                                        owner={owner}
                                        menu={menu}
                                        menuId={menu._id}
                                        categoryId={selectedEditCategoryId}
                                        itemId={item._id}
                                        showEditMenuItem={showEditMenuItem}
                                        setShowEditMenuItem={setShowEditMenuItem}
                                        refreshAllMenusOwner={refreshAllMenusOwner}
                                        refreshMenuOwner={refreshMenuOwner}
                                        menuDefaultLanguage={menu.defaultLanguage}
                                        getHandleDeleteMenuItem={handleDeleteMenuItem}
                                      />
                                    )}
                                  </div>
                                  <div className="relative">

                                    {openActions.type === 'item' && openActions.id === item._id && (
                                      <div className="absolute left-0 mt-2 w-40 bg-white border border-gray-300 rounded-md shadow-lg z-50 text-base">
                                        <button
                                          className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                          onClick={(e) => { e.stopPropagation(); handleOpenEditMenuItem(item._id, category._id); closeActions(); }}
                                        >
                                          <FiEdit2 className="inline mr-2" /> Modifica
                                        </button>
                                        <button
                                          className="w-full text-left px-4 py-2 hover:bg-gray-100"
                                          onClick={(e) => { e.stopPropagation(); handleDuplicateMenuItem(category._id, item._id); closeActions(); }}
                                          disabled={!menu}
                                        >
                                          <FontAwesomeIcon icon={faClone} className="inline mr-2" /> Duplica
                                        </button>
                                        <button
                                          className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600"
                                          onClick={(e) => { e.stopPropagation(); handleDeleteMenuItem(menu._id, category._id, item._id); closeActions(); }}
                                          disabled={!menu}
                                        >
                                          <FontAwesomeIcon icon={faTrash} className="inline mr-2" /> Elimina
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="font-primary italic p-2 text-sm text-center">Nessun piatto presente</div>
                          )}
                        </div>
                      )}

                      {/* Modale di CreateMenuItem */}
                      {showCreateMenuItem && selectedEditCategoryId === category._id && (
                        <CreateMenuItem
                          menu={menu}
                          menuId={menu._id}
                          categoryId={selectedEditCategoryId}
                          showCreateMenuItem={showCreateMenuItem}
                          setShowCreateMenuItem={setShowCreateMenuItem}
                          refreshAllMenusOwner={refreshAllMenusOwner}
                          refreshMenuOwner={refreshMenuOwner}
                          menuDefaultLanguage={menu.defaultLanguage}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="font-primary text-xs italic mb-4 mt-2">Questo menu non contiene ancora nessuna categoria</div>
              )}

              {/* Modale di CreateMenuCategory */}
              {showCreateMenuCategory && (
                <CreateMenuCategory
                  owner={owner}
                  menu={menu}
                  menuId={menu._id}
                  showCreateMenuCategory={showCreateMenuCategory}
                  setShowCreateMenuCategory={setShowCreateMenuCategory}
                  refreshAllMenusOwner={refreshAllMenusOwner}
                  refreshMenuOwner={refreshMenuOwner}
                  menuDefaultLanguage={menu.defaultLanguage}
                />
              )}

            </div>

          </div>

        }

      </div>

      {/* Sezione iframe per visualizzare il menu */}
      <div className="col-span-1 hidden lg:block">
        <div className="sticky top-20">
          <div className="relative mx-auto h-[700px] w-auto aspect-[430/932] overflow-hidden bg-no-repeat bg-center border border-gray-500 rounded-xl p-8">
            <div className="relative w-full h-full">
              {iframeUrl ? (
                <MyIframe
                  myMenuId={selectedMenuId}
                  showCreateMenu={showCreateMenu}
                  showEditMenu={showEditMenu}
                  showEditMenuCategory={showEditMenuCategory}
                  showCreateMenuItem={showCreateMenuItem}
                  showEditMenuItem={showEditMenuItem}
                  owner={owner}
                  iframeUrlIn={iframeUrl}
                  onUrlChange={handleIframeUrlChange}
                  menu={menu}
                />
              ) : (
                <div className="font-primary text-sm text-center"></div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modali globali */}
      <QRCodeModal isOpen={isQRCodeModalOpen} onRequestClose={closeQRCodeModal} url={iframeUrl} />

      {/* Loader */}
      {(isLoadingMenuCategoryDelete || isLoadingMenuDelete || isLoadingMenuItemDelete) && loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex items-center justify-center z-10 overflow-y-auto">
          <div className="flex flex-row justify-center gap-2 pt-2">
            <div className="custom-loader"></div>
          </div>
        </div>
      )}

      {/* Tooltip */}
      <Tooltip id="tooltip" />
    </div>
  );
};

export default MenuConfiguratorContainer;
