// TimeSlots.js
import React from 'react';
import Toggle from './Toggle';
import Buttons from './Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AiOutlinePlus } from 'react-icons/ai';


const TimeSlots = ({
  timeSlots,
  handleDayToggle,
  handleTimeSlotChange,
  handleRemoveTimeSlot,
  handleAddTimeSlot,
}) => {
  const weekDays = [
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
    'Domenica',
  ];

  return (
    <div className="time-slots w-full mt-3">
      {weekDays.map((day, index) => {
        const slot = timeSlots.find((slot) => slot.day === index);
        const isActive = !!slot;

        return (
          <div key={index} className="day-toggle mb-6">
            {/* Intestazione del Giorno */}
            <div className="flex items-center justify-between border-b border-gray-300 pb-2">
              <div className="flex items-center">
                <Toggle isOn={isActive} handleToggle={() => handleDayToggle(index)} />
                <span className="text-lg font-semibold text-gray-700 ml-4">{day}</span>
              </div>
              {isActive && (
                <Buttons
                  type="green_icon"
                  onClick={() => handleAddTimeSlot(index)}
                  aria-label="Aggiungi Orario"
                >
                  <AiOutlinePlus  className="sm:mr-1" />
                  {/* Nascondi testo su schermi piccoli */}
                  <span className="hidden sm:inline">Aggiungi Orario</span>
                </Buttons>
              )}
            </div>
            {/* Slot di Orario */}
            {isActive && (
              <div className="slots mt-1 space-y-1">
                {slot.slots.map((s, slotIndex) => (
                  <div key={slotIndex} className="slot bg-white p-4 rounded-md shadow-sm">
                    <div className="flex items-center space-x-2">
                      {/* Inizio */}
                      <div className="flex flex-col">
                        <label className="text-sm font-medium text-gray-600 block">
                          Inizio
                        </label>
                        <input
                          type="time"
                          value={s.startTime}
                          onChange={(e) =>
                            handleTimeSlotChange(
                              index,
                              slotIndex,
                              'startTime',
                              e.target.value
                            )
                          }
                          className="mt-1 w-full max-w-[100px] px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      {/* Fine */}
                      <div className="flex flex-col">
                        <label className="text-sm font-medium text-gray-600 block">
                          Fine
                        </label>
                        <input
                          type="time"
                          value={s.endTime}
                          onChange={(e) =>
                            handleTimeSlotChange(
                              index,
                              slotIndex,
                              'endTime',
                              e.target.value
                            )
                          }
                          className="mt-1 w-full max-w-[100px] px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                      {/* Pulsante Rimuovi */}
                      <Buttons
                        type="red_icon"
                        onClick={() => handleRemoveTimeSlot(index, slotIndex)}
                        aria-label="Rimuovi Orario"
                      >
                        <FontAwesomeIcon icon={faTrash} className="sm:mr-1" /> 

                        {/* Nascondi testo su schermi piccoli */}
                        <span className="hidden sm:inline">Elimina</span>
                      </Buttons>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TimeSlots;
