import React, { useEffect, useState } from 'react';

function MyIframe({ myMenuId, showCreateMenu, showEditMenu, showEditMenuCategory, showCreateMenuItem, showEditMenuItem, onUrlChange, owner, menu }) {
    const [iframeUrl, setIframeUrl] = useState('');
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [ownerId, setOwnerId] = useState(owner ? owner._id : ''); // Imposta subito l'ownerId
    const iFrameUrl = process.env.REACT_APP_IFRAME;

    useEffect(() => {
    if (owner) {
        setOwnerId(owner._id)
    }}, [owner])

    useEffect(() => {

        if (myMenuId && ownerId) {
            const newUrl = `${iFrameUrl}/menu/${ownerId}/${myMenuId}?t=${timestamp}`;
            const iFrameQRCode = `${iFrameUrl}/menu/${ownerId}`;
            setIframeUrl(newUrl);
            onUrlChange(iFrameQRCode);
        } else if (ownerId) {
            const newUrl = `${iFrameUrl}/menu/${ownerId}?t=${timestamp}`;
            const iFrameQRCode = `${iFrameUrl}/menu/${ownerId}`;
            setIframeUrl(newUrl);
            onUrlChange(iFrameQRCode);
        }
    }, [myMenuId, ownerId, timestamp]);

    useEffect(() => {
        if (!showCreateMenu && !showEditMenu && !showEditMenuCategory && !showCreateMenuItem && !showEditMenuItem ) {
            setTimestamp(new Date().getTime());
        } 
    }, [menu, owner]);

    return (
        <div className="relative w-full h-0 pb-[216.28%]"> {/* Aspect ratio container */}
            <iframe
                src={iframeUrl}
                title="Menu Preview"
                className="absolute -top-60 -left-32 w-[200%] h-[200%] scale-[0.6]"
            />
        </div>
    );
}

export default MyIframe;
