/**
 * Aggiorna una parte specifica del menu in base al parametro 'type'.
 * @param {Object} menu - Il menu esistente.
 * @param {Object} details - I dettagli della parte da aggiornare (categoria o altro).
 * @param {String} type - Il tipo di aggiornamento da effettuare ('categoryUpdate', 'menuUpdate', 'categoryDelete', ecc.).
 * @param {String} id - L'ID dell'elemento da aggiornare o cancellare (opzionale, per categorie o elementi specifici).
 * @param {String} id2 - L'ID dell'elemento da aggiornare o cancellare (opzionale, per categorie o elementi specifici). 
* @returns {Object} - Ritorna il menu aggiornato.
 */
const updateMenu = (menu, details, type, id, id2) => {
    switch (type) {
        case 'menuCreate':
            if (details && id)  {
                return menuCreate(details, id);
            }
        case 'menuUpdate':
            if (menu && details) {
                return updateMenuDetails(menu, details);
            }  
        case 'categoryCreate':
            if (menu && details) {
                return createCategory(menu, details);
            }
        case 'categoryUpdate':
            if (menu && details && id) {
                return updateCategory(menu, details, id);
            }
        case 'categoryDelete':
            if (menu && id) {
                return deleteCategory(menu, id);
            }  
        case 'menuItemCreate':
            if (menu && details && id) {
                return createMenuItem(menu, details, id); 
            }
        case 'menuItemUpdate':
            if (menu && details && id) {
                return updateMenuItem(menu, details, id, id2); 
            }
        case 'menuItemDelete':
            if (menu && id && id2) {
                return deleteMenuItem(menu, id, id2);
            }
        case 'duplicateMenu':
            if (menu ) {
                return duplicateMenu(menu);
            }
        case 'duplicateCategory':
            if (menu && id ) {
                return duplicateCategory(menu, id);
            }
        case 'duplicateItem':
            if (menu && id && id2) {
                return duplicateMenuItem(menu, id, id2);
            }
        default:
            throw new Error(`Tipo di aggiornamento non supportato: ${type}`);
    }
};

const menuCreate = (details, id) => {
    // Filtra le traduzioni eliminando quelle che hanno nome o descrizione vuota
    const filteredTranslations = details.menu?.translations?.filter(
        translation => translation.name 
    ) || [];

    // Crea la nuova struttura del menu
    const newMenu = {
        owner: id || '',  // Assicurati che l'owner sia fornito nei dettagli
        defaultLanguage: details.defaultLanguage || 'ita',  // Lingua predefinita, "ita" se non fornita
        settings: {
            alwaysActive: details.settings?.alwaysActive ?? true,  // Se "alwaysActive" è presente, usa quello, altrimenti imposta a true
            daysActive: details.settings?.daysActive || [],  // Array di giorni attivi
            timeSlot: {
                startTime: details.settings?.timeSlot?.startTime || '',
                endTime: details.settings?.timeSlot?.endTime || ''
            },
            timeSlots: details.settings?.timeSlots || []  // Array di fasce orarie
        },
        menu: {
            color: details.menu?.color || '',  // Colore del menu
            image: details.menu?.image || '',  // Immagine del menu
            description: details.menu?.description || '',  // Descrizione del menu
            translations: filteredTranslations,  // Traduzioni filtrate del menu
            menuCategory: details.menu?.menuCategory || []  // Categorie del menu (inizialmente vuote)
        },
        createdAt: new Date().toISOString(),  // Data di creazione
        updatedAt: new Date().toISOString(),  // Data di aggiornamento
        __v: 0  // Versione del documento, inizia a 0
    };

    return newMenu;
};

const updateMenuDetails = (menu, details) => {
   // Filtra le traduzioni eliminando quelle che hanno nome o descrizione vuota
   const filteredTranslations = details.menu?.translations?.filter(
    translation => translation.name 
) || [];

// Crea la nuova struttura del menu
const newMenu = {
    owner: menu.menu?.owner,
    defaultLanguage: details.defaultLanguage || 'ita',  // Lingua predefinita, "ita" se non fornita
    settings: {
        alwaysActive: details.settings?.alwaysActive ?? true,  // Se "alwaysActive" è presente, usa quello, altrimenti imposta a true
        daysActive: details.settings?.daysActive || [],  // Array di giorni attivi
        timeSlot: {
            startTime: details.settings?.timeSlot?.startTime || '',
            endTime: details.settings?.timeSlot?.endTime || ''
        },
        timeSlots: details.settings?.timeSlots || []  // Array di fasce orarie
    },
    menu: {
        color: details.menu?.color || '',  // Colore del menu
        image: details.menu?.image || '',  // Immagine del menu
        translations: filteredTranslations,  // Traduzioni filtrate del menu
        menuCategory: menu.menu?.menuCategory || []  // Categorie del menu (inizialmente vuote)
    },
    createdAt: new Date().toISOString(),  // Data di creazione
    updatedAt: new Date().toISOString(),  // Data di aggiornamento
    __v: 0  // Versione del documento, inizia a 0
};

return newMenu;
};


const createCategory = (menu, details) => {
    const newCategory = {
        color: details.color,
        image: details.image,
        price: details.price || null,
        isComplete: details.isComplete || false,
        translations: details.translations,
        menuItems: []  
    };

    const updatedMenuCategories = [...menu.menu.menuCategory, newCategory];

    const updatedMenu = {
        ...menu,
        menu: {
            ...menu.menu,
            menuCategory: updatedMenuCategories,
        },
    };

    return updatedMenu;
};

const updateCategory = (menu, details, id) => {
    const newCategory = {
        _id: id,
        color: details.color,
        image: details.image,
        price: details.price || null,
        isComplete: details.isComplete || false,
        translations: details.translations,
    };

    // Trova l'indice della categoria esistente
    const existingCategoryIndex = menu.menu.menuCategory.findIndex(category => category._id === newCategory._id);

    // Se l'indice esiste, mantieni i vecchi menuItems
    if (existingCategoryIndex !== -1) {
        newCategory.menuItems = menu.menu.menuCategory[existingCategoryIndex].menuItems;
    }

    // Crea una copia profonda delle categorie e aggiorna la categoria specifica
    const updatedMenuCategories = [...menu.menu.menuCategory];
    updatedMenuCategories[existingCategoryIndex] = newCategory;

    // Crea una copia del menu e aggiorna il campo delle categorie
    const updatedMenu = {
        ...menu,
        menu: {
            ...menu.menu,
            menuCategory: updatedMenuCategories,
        },
    };

    return updatedMenu;
};

const deleteCategory = (menu, id) => {
    // Filtra le categorie per rimuovere quella con l'ID specificato
    const updatedMenuCategories = menu.menu.menuCategory.filter(category => category._id !== id);

    // Crea una copia del menu e aggiorna il campo delle categorie
    const updatedMenu = {
        ...menu,
        menu: {
            ...menu.menu,
            menuCategory: updatedMenuCategories,
        },
    };

    return updatedMenu;
};

const createMenuItem = (menu, details, categoryId) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const updatedMenu = JSON.parse(JSON.stringify(menu));

    // Troviamo l'indice della categoria con l'ID specificato
    const categoryIndex = updatedMenu.menu.menuCategory.findIndex(
        (category) => category._id === categoryId
    );

    if (categoryIndex === -1) {
        throw new Error(`Categoria con ID ${categoryId} non trovata.`);
    }

    // Creiamo un nuovo menuItem con i dettagli forniti
    const newMenuItem = {
        ...details,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
    };

    // Aggiungiamo il nuovo menuItem alla categoria specificata
    updatedMenu.menu.menuCategory[categoryIndex].menuItems.push(newMenuItem);

    // Aggiorniamo la data di modifica del menu
    updatedMenu.updatedAt = new Date().toISOString();

    return updatedMenu;
};

const updateMenuItem = (menu, details, categoryId, itemId) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const updatedMenu = JSON.parse(JSON.stringify(menu));

    // Troviamo l'indice della categoria con l'ID specificato
    const categoryIndex = updatedMenu.menu.menuCategory.findIndex(
        (category) => category._id === categoryId
    );

    if (categoryIndex === -1) {
        throw new Error(`Categoria con ID ${categoryId} non trovata.`);
    }

    // Troviamo l'indice del menuItem da aggiornare
    const menuItemIndex = updatedMenu.menu.menuCategory[categoryIndex].menuItems.findIndex(
        (menuItem) => menuItem._id === itemId
    );

    if (menuItemIndex === -1) {
        throw new Error(`Menu item con ID ${itemId} non trovato.`);
    }

    // Aggiorniamo i campi del menuItem solo se presenti nei dettagli
    const updatedMenuItem = {
        ...updatedMenu.menu.menuCategory[categoryIndex].menuItems[menuItemIndex],
        ...details,  // Applica i nuovi dettagli
        updatedAt: new Date().toISOString(),  // Aggiorniamo la data di modifica
    };

    // Sostituiamo il vecchio menuItem con quello aggiornato
    updatedMenu.menu.menuCategory[categoryIndex].menuItems[menuItemIndex] = updatedMenuItem;

    // Aggiorniamo la data di modifica del menu
    updatedMenu.updatedAt = new Date().toISOString();

    return updatedMenu;
};

const deleteMenuItem = (menu, categoryId, itemId) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const updatedMenu = JSON.parse(JSON.stringify(menu));

    // Troviamo l'indice della categoria con l'ID specificato
    const categoryIndex = updatedMenu.menu.menuCategory.findIndex(
        (category) => category._id === categoryId
    );

    if (categoryIndex === -1) {
        throw new Error(`Categoria con ID ${categoryId} non trovata.`);
    }

    // Filtra i menuItems per rimuovere quello con l'ID specificato
    const updatedMenuItems = updatedMenu.menu.menuCategory[categoryIndex].menuItems.filter(
        (menuItem) => menuItem._id !== itemId
    );

    // Aggiorniamo la categoria con i menuItems filtrati
    updatedMenu.menu.menuCategory[categoryIndex].menuItems = updatedMenuItems;

    // Aggiorniamo la data di modifica del menu
    updatedMenu.updatedAt = new Date().toISOString();

    return updatedMenu;
};


const duplicateMenu = (menu) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const duplicatedMenu = JSON.parse(JSON.stringify(menu));

    // Rimuoviamo l'_id del menu
    duplicatedMenu._id = undefined;

    // Rimuoviamo gli _id delle categorie e degli item
    duplicatedMenu.menu.menuCategory = duplicatedMenu.menu.menuCategory.map(category => ({
        ...category,
        _id: undefined, // Rimuoviamo l'_id della categoria
        translations: category.translations.map(translation => ({
            ...translation,
            _id: undefined  // Rimuoviamo l'_id dalle traduzioni della categoria
        })),
        menuItems: category.menuItems.map(item => ({
            ...item,
            _id: undefined,  // Rimuoviamo l'_id dai menuItems
            translations: item.translations.map(translation => ({
                ...translation,
                _id: undefined  // Rimuoviamo l'_id dalle traduzioni dei menuItems
            }))
        }))
    }));

    // Rimuoviamo l'_id dell'owner se necessario (opzionale, se non si vuole duplicare l'owner)
    duplicatedMenu.owner = undefined;

    // Impostiamo le date di creazione e aggiornamento
    duplicatedMenu.createdAt = new Date().toISOString();
    duplicatedMenu.updatedAt = new Date().toISOString();

    return duplicatedMenu;
};


const duplicateCategory = (menu, categoryId) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const updatedMenu = JSON.parse(JSON.stringify(menu));

    // Troviamo l'indice della categoria con l'ID specificato
    const categoryIndex = updatedMenu.menu.menuCategory.findIndex(
        (category) => category._id === categoryId
    );

    if (categoryIndex === -1) {
        throw new Error(`Categoria con ID ${categoryId} non trovata.`);
    }

    // Cloniamo la categoria trovata e rimuoviamo il campo _id
    const clonedCategory = {
        ...updatedMenu.menu.menuCategory[categoryIndex],
        _id: undefined, // Rimuoviamo l'_id
        translations: updatedMenu.menu.menuCategory[categoryIndex].translations.map(translation => ({
            ...translation,
            _id: undefined  // Rimuoviamo l'_id dalle traduzioni
        })),
        menuItems: updatedMenu.menu.menuCategory[categoryIndex].menuItems.map(item => ({
            ...item,
            _id: undefined,  // Rimuoviamo l'_id dai menuItems
            translations: item.translations.map(translation => ({
                ...translation,
                _id: undefined  // Rimuoviamo l'_id anche dalle traduzioni dei menuItems
            }))
        }))
    };

    // Aggiungiamo la categoria clonata alla lista delle categorie
    updatedMenu.menu.menuCategory.push(clonedCategory);

    // Aggiorniamo la data di modifica del menu
    updatedMenu.updatedAt = new Date().toISOString();

    return updatedMenu;
};


const duplicateMenuItem = (menu, categoryId, itemId) => {
    // Creiamo una copia profonda del menu per evitare mutazioni
    const updatedMenu = JSON.parse(JSON.stringify(menu));

    // Troviamo l'indice della categoria con l'ID specificato
    const categoryIndex = updatedMenu.menu.menuCategory.findIndex(
        (category) => category._id === categoryId
    );

    if (categoryIndex === -1) {
        throw new Error(`Categoria con ID ${categoryId} non trovata.`);
    }

    // Troviamo l'indice del piatto con l'ID specificato
    const itemIndex = updatedMenu.menu.menuCategory[categoryIndex].menuItems.findIndex(
        (menuItem) => menuItem._id === itemId
    );

    if (itemIndex === -1) {
        throw new Error(`Piatto con ID ${itemId} non trovato nella categoria ${categoryId}.`);
    }

    // Cloniamo il piatto trovato e rimuoviamo il campo _id
    const clonedMenuItem = {
        ...updatedMenu.menu.menuCategory[categoryIndex].menuItems[itemIndex],
        _id: undefined,  // Rimuoviamo l'_id dal menuItem
        translations: updatedMenu.menu.menuCategory[categoryIndex].menuItems[itemIndex].translations.map(translation => ({
            ...translation,
            _id: undefined  // Rimuoviamo l'_id dalle traduzioni del menuItem
        }))
    };

    // Aggiungiamo il piatto duplicato alla categoria
    updatedMenu.menu.menuCategory[categoryIndex].menuItems.push(clonedMenuItem);

    // Aggiorniamo la data di modifica del menu
    updatedMenu.updatedAt = new Date().toISOString();

    return updatedMenu;
};



module.exports = updateMenu;
