import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Importazione corretta
import Homepage from "../pages/Homepage";
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/loginsSlice'; // Assicurati che il percorso sia corretto

const auth = () => {
    return localStorage.getItem('userLoggedIn'); // Rimosso JSON.parse
};

const useSession = () => {
    const session = auth();
    let decodedSession = null;

    if (session) {
        try {
            decodedSession = jwtDecode(session);
        } catch (error) {
            console.error('Invalid token:', error);
        }
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!session) {
            console.log('session no auth');
            dispatch(logout());
            navigate('/', { replace: true });
        } else if (decodedSession && decodedSession.exp < (Date.now() / 1000)) {
            console.log('token scaduto');
            dispatch(logout());
            navigate('/', { replace: true });
        }
    }, [navigate, dispatch, session, decodedSession]);

    return decodedSession;
};

const ProtectedRoutes = () => {
    const isAuthorized = auth();
    const session = useSession();

    return isAuthorized && session ? <Outlet /> : <Homepage />;
}

export default ProtectedRoutes;
