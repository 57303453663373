import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuThunk } from '../redux/menusSlice';
import { getIngredients, getAllergens } from '../redux/ingredientsSlice';
import CreateIngredient from './CreateIngredient';
import Modal from './modal/Modal';
import ImageSection from './modal/ImageSection';
import ColorSection from './modal/ColorSection';
import Translations from './modal/Translations';
import Input from './modal/Input';
import { FiPlus } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import Toggle from './modal/Toggle';

const updateMenu = require('./logic/updateMenu.js');

const EditMenuItem = ({
    owner,
    menu,
    menuId,
    categoryId,
    itemId,
    showEditMenuItem,
    setShowEditMenuItem,
    refreshAllMenusOwner,
    refreshMenuOwner,
    menuDefaultLanguage,
    getHandleDeleteMenuItem,
    handleDuplicateMenuItem,
}) => {
    const url = process.env.REACT_APP_SERVER_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const isLoading = useSelector((state) => state.menus.menuUpdatingIsLoading);
    const errorMessage = useSelector((state) => state.menus.errorMenuUpdating);
    const successMessage = useSelector((state) => state.menus.successMenuUpdating);
    const ingredients = useSelector((state) => state.ingredients.ingredientsOwner);
    const menuCategoryTarget = menu.menu?.menuCategory?.find((cat) => cat._id === categoryId);
    const menuItemTarget = menuCategoryTarget.menuItems?.find((itm) => itm._id === itemId);
    const [showCreateIngredient, setShowCreateIngredient] = useState(false);
    const categoryIsComplete = menu.menu.menuCategory.find((category) => category._id === categoryId)
        .isComplete;
    const [allergens, setAllergens] = useState([]);

    // Added state variables for validation
    const [nameError, setNameError] = useState(false); // Tracks validation error for "Nome"
    const [ingredientsError, setIngredientsError] = useState(false); // Tracks validation error for "Ingredienti"
    const [priceError, setPriceError] = useState(false); // Tracks validation error for "Prezzo"
    const [uploadMessage, setUploadMessage] = useState(false); // Handles upload messages
    const [uploadErrorState, setUploadErrorState] = useState(false); // Handles upload errors

    const [outputTranslations, setOutputTranslations] = useState([]);
    const defaultLanguage = menu.defaultLanguage;

    const [itemDetails, setItemDetails] = useState({
        foodOrBeverage: menuItemTarget.foodOrBeverage,
        translations: menu.menu.translations.map((lang) => {
            const existingTranslation = menuItemTarget?.translations?.find(
                (t) => t.language === lang.language
            );
            return {
                language: lang.language,
                name: existingTranslation ? existingTranslation.name : '',
                description: existingTranslation ? existingTranslation.description : '',
            };
        }),
        color: menuItemTarget.color || '',
        image: menuItemTarget.image || '',
        price: menuItemTarget.price || '',
        isActive: menuItemTarget.isActive || false,
        isVegetarian: menuItemTarget.isVegetarian || false,
        isVegan: menuItemTarget.isVegan || false,
        isMeat: menuItemTarget.isMeat || false,
        isFish: menuItemTarget.isFish || false,
        ingredients: menuItemTarget.ingredients || [],
        allergens: menuItemTarget.allergens || [],
    });

    const dispatch = useDispatch();

    const entireState = useSelector((state) => state);

    useEffect(() => {
        if (outputTranslations && outputTranslations.length > 0) {
            const formattedTranslations = outputTranslations.map((lang) => ({
                language: lang.language,
                name: lang.name,
                description: lang.description,
            }));
            setItemDetails((prevItemDetails) => ({
                ...prevItemDetails,
                translations: formattedTranslations || [],
            }));
        }
    }, [outputTranslations]);

    const [inputImage, setInputImage] = useState(menuItemTarget.image || '');
    const [uploadedFileName, setUploadedFileName] = useState(menuItemTarget.image || '');

    useEffect(() => {
        const category = menu.menu.menuCategory.find((cat) => cat._id === categoryId);
        if (category) {
            const item = category.menuItems.find((item) => item._id === itemId);
            if (item.image !== 'false') {
                const itemImage = item ? item.image : null;
                setInputImage(itemImage);
                setUploadedFileName(itemImage);
            }
        }
    }, [menu, categoryId, itemId]);

    useEffect(() => {
        setItemDetails((prevState) => ({
            ...prevState,
            image: uploadedFileName,
        }));
    }, [uploadedFileName]);

    const handleRemoveImage = () => {
        setInputImage('');
        setUploadedFileName('');
        setItemDetails((prevState) => ({
            ...prevState,
            image: '',
        }));
        setUploadMessage(false);
        setUploadErrorState(false);
        document.getElementById('fileInput').value = ''; // Reset file input
    };

    const handleShowCreateIngredient = () => {
        setShowCreateIngredient(true);
    };

    useEffect(() => {
        const fetchAllergens = async () => {
            try {
                const resultAction = await dispatch(getAllergens());
                if (getAllergens.fulfilled.match(resultAction)) {
                    const allergeni = resultAction.payload;
                    setAllergens(allergeni);
                } else {
                    console.error('Errore nella chiamata:', resultAction.error.message);
                }
            } catch (error) {
                console.error('Errore durante la chiamata a getAllergens:', error);
            }
        };
        fetchAllergens();
    }, [dispatch]);

    const allergenOptions = allergens.map((allergen) => ({
        value: allergen._id,
        label: allergen.name,
    }));

    useEffect(() => {
        if (menuItemTarget) {
            setItemDetails({
                foodOrBeverage: menuItemTarget.foodOrBeverage,
                translations: menu.menu.translations.map((lang) => {
                    const existingTranslation = menuItemTarget?.translations?.find(
                        (t) => t.language === lang.language
                    );
                    return {
                        language: lang.language,
                        name: existingTranslation ? existingTranslation.name : '',
                        description: existingTranslation ? existingTranslation.description : '',
                    };
                }),
                color: menuItemTarget.color || '',
                image: menuItemTarget.image || '',
                price: menuItemTarget.price || '',
                isActive: menuItemTarget.isActive || false,
                isVegetarian: menuItemTarget.isVegetarian || false,
                isVegan: menuItemTarget.isVegan || false,
                isMeat: menuItemTarget.isMeat || false,
                isFish: menuItemTarget.isFish || false,
                ingredients: menuItemTarget.ingredients || [],
                allergens: menuItemTarget.allergens || [],
            });
        }
    }, [menuItemTarget]); // Removed 'ingredients' from dependencies


    useEffect(() => {
        dispatch(getIngredients());
    }, [dispatch]);

    const handleAllergenChange = (selectedOptions) => {
        const selectedAllergenValues = selectedOptions.map((option) => option.value);
        setItemDetails((prev) => ({
            ...prev,
            allergens: selectedAllergenValues,
        }));
    };

    const [color, setColor] = useState(menuItemTarget.color);

    const handleColorChange = (color) => {
        setColor(color.hex);
        setItemDetails((prevState) => ({
            ...prevState,
            color: color.hex,
        }));
    };

    const deleteColor = () => {
        setColor('');
        setItemDetails((prevState) => ({
            ...prevState,
            color: '',
        }));
    };

    const closeEditMenuItem = () => {
        setShowEditMenuItem(false);
    };

    const createIdValueObject = (ingredients, defaultLanguage) => {
        if (!ingredients || ingredients.length === 0) {
            return [];
        }
        return ingredients.reduce((acc, ingredient) => {
            const translation = ingredient.translations.find((t) => t.language === defaultLanguage);
            if (translation) {
                acc.push({ value: ingredient._id, label: translation.translatedName });
            }
            return acc;
        }, []);
    };

    const ingredientsOptions = createIdValueObject(ingredients, defaultLanguage);

    const selectIngredient = (selectedOptions) => {
        const selectedIds = selectedOptions.map((option) => option.value);

        setItemDetails((prev) => ({
            ...prev,
            ingredients: selectedIds,
        }));
        if (selectedIds.length > 0) {
            setIngredientsError(false);
            setError(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        if (name === 'name' || name === 'description') {
            if (name === 'name' && value.trim() !== '') {
                setNameError(false);
                setError(false);
            }
            setItemDetails((prevState) => ({
                ...prevState,
                translations: prevState.translations.map((translation) =>
                    translation.language === defaultLanguage
                        ? { ...translation, [name]: inputValue }
                        : translation
                ),
            }));
        } else if (name === 'price') {
            // Reset price error when the price input changes
            if (parseFloat(value) >= 0) {
                setPriceError(false);
                setError(false);
            }
            setItemDetails((prevState) => ({
                ...prevState,
                [name]: inputValue,
            }));
        } else {
            setItemDetails((prevState) => ({
                ...prevState,
                [name]: inputValue,
            }));
        }
    };

    const handleToggleChange = (name) => {
        setItemDetails((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validation
        const name = itemDetails.translations.find(t => t.language === defaultLanguage)?.name.trim();
        const isFood = itemDetails.foodOrBeverage === 'food';
        const hasIngredients = itemDetails.ingredients.length > 0;
        const price = parseFloat(itemDetails.price);

        let hasError = false;

        if (!name) {
            setNameError(true);
            hasError = true;
        }

        if (isFood && !hasIngredients) {
            setIngredientsError(true);
            hasError = true;
        }

        if (isNaN(price) || price <= 0) {
            setPriceError(true);
            hasError = true;
        }

        if (hasError) {
            setError(true);
            return;
        }

        setLoading(true);
        setError(false);
        setSuccess(false);

        const updatedMenu = updateMenu(menu, itemDetails, 'menuItemUpdate', categoryId, itemId);

        try {
            const response = await dispatch(updateMenuThunk({ menuId, updatedMenu }));
            if (updateMenuThunk.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshMenuOwner(menuId);
                closeEditMenuItem();
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Error updating the menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    const handleDeleteMenuItem = async (menuId, categoryId, itemId) => {
        await getHandleDeleteMenuItem(menuId, categoryId, itemId);
        setShowEditMenuItem(false);
    };

    return (
        <>
            {showEditMenuItem && (
                <Modal
                    handleSubmit={handleSubmit}
                    closeFunction={closeEditMenuItem}
                    title="Modifica Piatto"
                    isLoading={isLoading}
                    loading={loading}
                    success={success}
                    successMessage={successMessage}
                    error={error}
                    errorMessage={errorMessage}
                    deleteFunction={() => handleDeleteMenuItem(menuId, categoryId, itemId)}
                >
                    {/* "Nome" Input with Validation */}
                    <Input
                        description={
                            itemDetails?.translations?.find((t) => t.language === defaultLanguage)?.name || ''
                        }
                        name="name"
                        onChange={handleInputChange}
                        tipo="InputText"
                        hasError={nameError} // Pass the error state
                        ariaDescribedby="name-error" // Enhance accessibility
                        maxLength={100} // Limite di caratteri aggiunto
                    >
                        <div className="text-2xl">
                            Nome <span className="text-red-500">*</span> {/* Indicate required */}
                        </div>
                    </Input>
                    {nameError && (
                        <span id="name-error" className="text-red-500">
                            Il nome del piatto è obbligatorio.
                        </span>
                    )}

                    {/* "Descrizione" Input */}
                    <Input
                        description={
                            itemDetails?.translations?.find((t) => t.language === defaultLanguage)?.description || ''
                        }
                        name="description"
                        onChange={handleInputChange}
                        tipo="InputText"
                        maxLength={500} // Limite di caratteri aggiunto

                    >
                        <div className="text-2xl">Descrizione</div>
                    </Input>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Traduzioni</div>

                    <Translations
                        defaultLanguage={defaultLanguage}
                        inputTranslations={itemDetails.translations}
                        outputTranslations={outputTranslations}
                        setOutputTranslations={setOutputTranslations}
                    ></Translations>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Ingredienti e allergeni</div>

                    <div className="flex flex-col items-start">
                        <div className="flex flex-col md:flex-row items-center justify-center gap-2 my-1 mt-8">
                            <span className="font-bold">Cibo o Bevanda</span>
                            <select
                                className="border rounded-lg border-gray-400 p-2 bg-transparent"
                                name="foodOrBeverage"
                                value={itemDetails.foodOrBeverage}
                                onChange={handleInputChange}
                            >
                                <option value="food">Cibo</option>
                                <option value="beverage">Bevanda</option>
                            </select>
                        </div>
                    </div>

                    <>
                        <div className="flex flex-col mt-4 ">
                            {itemDetails.foodOrBeverage === 'food' &&
                                <h3 className="font-bold col-span-2 text-start">
                                    Ingredienti <span className="text-red-500">*</span>
                                </h3>
                            }
                            {itemDetails.foodOrBeverage !== 'food' &&
                                <h3 className="font-bold col-span-2 text-start">
                                    Ingredienti 
                                </h3>
                            }
                            <div className="grid grid-cols-12 gap-2 items-center">
                                {/* Updated "Ingredienti" Label with Red Asterisk */}

                                {/* Wrapped Select and "+" Button in a Flex Container */}
                                <div className="col-span-12 flex items-center">
                                    <Select
                                        isMulti
                                        name="ingredients"
                                        options={ingredientsOptions} // Usa le opzioni mappate
                                        className={`basic-multi-select flex-grow ${ingredientsError ? 'border-red-500' : ''
                                            }`} // Apply border if error
                                        classNamePrefix="select"
                                        value={ingredientsOptions?.filter((option) =>
                                            itemDetails.ingredients.includes(option.value)
                                        )} // Mappa i valori selezionati
                                        onChange={selectIngredient}
                                        placeholder="Cerca ingredienti..."
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderColor: ingredientsError ? 'red' : provided.borderColor,
                                                '&:hover': {
                                                    borderColor: ingredientsError ? 'red' : provided.borderColor,
                                                },
                                            }),
                                        }}
                                    />
                                    {/* "+" Button aligned inline with Select */}
                                    <button
                                        className="green-background hover:bg-green-600 text-center text-white h-9 w-9 ml-2 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowCreateIngredient();
                                        }}
                                        data-tooltip-id="tooltip"
                                        data-tooltip-content="Personalizza"
                                    >
                                        <FiPlus className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                            {/* Error Message Positioned Below Ingredients */}
                            {ingredientsError && (
                                <span className="text-red-500 mt-1 col-span-12">
                                    Seleziona almeno un ingrediente.
                                </span>
                            )}
                        </div>
                    </>

                    {showCreateIngredient && (
                        <CreateIngredient
                            showIngredientCreation={showCreateIngredient}
                            setShowIngredientCreation={setShowCreateIngredient}
                            menuDefaultLanguage={menuDefaultLanguage}
                            ingredients={ingredients}
                        />
                    )}
                    <h3 className="font-bold col-span-2 text-start mt-4">Allergeni</h3>

                    <div className="">
                        <Select
                            isMulti
                            name="allergens"
                            options={allergenOptions}
                            className="basic-multi-select w-full col-span-12"
                            classNamePrefix="select"
                            value={allergenOptions.filter((option) =>
                                itemDetails.allergens.includes(option.value)
                            )}
                            onChange={handleAllergenChange}
                            placeholder="Seleziona allergeni..."
                        />
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Info Piatto</div>

                    <div className="mt-6 flex flex-col justify-center my-1">
                        <div className="flex flex-row items-center flex-wrap text-base gap-2">
                            <div className="flex items-center mr-2">
                                <Toggle
                                    isOn={itemDetails.isActive}
                                    handleToggle={() => handleToggleChange('isActive')}
                                />
                                <span className="ml-2 text-gray-700">Attivo</span>
                            </div>
                            {itemDetails.foodOrBeverage === 'food' && (
                                <>
                                    <div className="flex items-center mr-2">
                                        <Toggle
                                            isOn={itemDetails.isVegetarian}
                                            handleToggle={() => handleToggleChange('isVegetarian')}
                                        />
                                        <span className="ml-2 text-gray-700">Vegetariano</span>
                                    </div>
                                    <div className="flex items-center mr-2">
                                        <Toggle
                                            isOn={itemDetails.isVegan}
                                            handleToggle={() => handleToggleChange('isVegan')}
                                        />
                                        <span className="ml-2 text-gray-700">Vegano</span>
                                    </div>
                                    <div className="flex items-center mr-2">
                                        <Toggle
                                            isOn={itemDetails.isMeat}
                                            handleToggle={() => handleToggleChange('isMeat')}
                                        />
                                        <span className="ml-2 text-gray-700">Carne</span>
                                    </div>
                                    <div className="flex items-center mr-2">
                                        <Toggle
                                            isOn={itemDetails.isFish}
                                            handleToggle={() => handleToggleChange('isFish')}
                                        />
                                        <span className="ml-2 text-gray-700">Pesce</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row item-center mt-6">
                        <div className="flex flex-col items-start">
                            {!categoryIsComplete && (
                                <div className="flex flex-col md:flex-row justify-between gap-2 items-center my-1">
                                    <h3 className="font-bold">Prezzo</h3>
                                    <div className="p-2 w-40 flex flex-row justify-between items-center">
                                        <input
                                            className={`border rounded-lg p-2 bg-transparent ${
                                                priceError ? 'border-red-500' : 'border-gray-400'
                                            }`}
                                            value={itemDetails.price}
                                            name="price"
                                            onChange={handleInputChange}
                                            type="number"
                                            min="0"
                                            step="any"
                                            aria-describedby="price-error" // Enhance accessibility
                                        />
                                    </div>
                                </div>
                            )}
                            {/* Error Message for Price */}
                            {!categoryIsComplete && priceError && (
                                <span id="price-error" className="text-red-500 mt-1 col-span-12">
                                    Il prezzo non è corretto
                                </span>
                            )}
                        </div>
                    </div>

                    <hr className="border-gray-400 mt-8"></hr>
                    <div className="text-2xl font-bold mt-4">Layout</div>

                    <div className="flex flex-col items-start">
                        <ColorSection
                            handleColorChange={handleColorChange}
                            deleteColor={deleteColor}
                            color={color}
                        ></ColorSection>

                        <ImageSection
                            inputImage={inputImage}
                            handleRemoveImage={handleRemoveImage}
                            setUploadedFileName={setUploadedFileName}
                            aspect={2 / 2}
                            maxSizeMB={0.1}
                            maxWidthOrHeight={1200}
                            uploadMessage={uploadMessage}
                            setUploadMessage={setUploadMessage}
                            uploadError={uploadErrorState}
                            setUploadError={setUploadErrorState}
                        ></ImageSection>
                    </div>



                    {/* Feedback Messages */}
                    <div className="flex flex-row justify-center gap-2">
                        {/* Additional buttons or elements can be added here if needed */}
                    </div>
                    {isLoading && loading && (
                        <div className="flex flex-row justify-center gap-2 pt-2">
                            <div className="custom-loader"></div>
                        </div>
                    )}
                    {success && successMessage && (
                        <div className="flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs">
                            {successMessage}
                        </div>
                    )}
                    {error && errorMessage && (
                        <div className="flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs">
                            {errorMessage}
                        </div>
                    )}
                </Modal>
            )}
            <Tooltip id="tooltip" className="z-50" />
        </>
    );
};

export default EditMenuItem;
