export function Footer({ title, description, socials, menus, copyright }) {

  return (

    <footer className="mt-8 mb-4 border-t pt-4 text-center">
      <p>© 2024 Nemù. Tutti i diritti riservati.</p>
      <p className="mt-2">Email: info@nemu-app.com</p>
    </footer>
  );
}


Footer.displayName = "/src/components/Footer.jsx";

export default Footer;
