import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api'; // Importa l'istanza personalizzata


const apiUrlOwners = `/owners/`;

// Thunks
export const registerOwner = createAsyncThunk('owners/register', async (ownerData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${apiUrlOwners}create`, ownerData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message || error.response.data.error.message);
    } else {
      throw error;
    }
  }
});

export const getOwnerById = createAsyncThunk('owners/getOwnerById', async (ownerId) => {
  try {
    const response = await api.get(`${apiUrlOwners}${ownerId}`, {
    });
    return response.data;
  } catch (error) {
    throw new Error('Error retrieving user data');
  }
});

export const verifyOwner = createAsyncThunk('owners/verifyOwner', async (token, { rejectWithValue }) => {
  try {
    const response = await api.get(`${apiUrlOwners}verify?token=${token}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    } else {
      throw error;
    }
  }
});

export const resendVerifyOwner = createAsyncThunk('owners/resend-verification', async (email) => {
  try {
    const response = await api.post(`${apiUrlOwners}resend-verification?email=${email}`);
    return response.data;
  } catch (error) {
    throw new Error('Error resending verification');
  }
});

export const editOwner = createAsyncThunk('owners/editOwner', async ({ ownerId, ownerData }, { rejectWithValue }) => {
  try {
    const response = await api.patch(`${apiUrlOwners}${ownerId}/update`, ownerData, {
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message || error.response.data.error.message);
    } else {
      throw error;
    }
  }
});

export const editEmailOwner = createAsyncThunk('owners/editEmailOwner', async ({ ownerId, email }, { rejectWithValue }) => {
  try {
    const response = await api.patch(`${apiUrlOwners}${ownerId}/email`, { email }, {
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message || error.response.data.error.message);
    } else {
      throw error;
    }
  }
});

export const deleteOwner = createAsyncThunk('owners/deleteOwner', async ({ ownerId }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${apiUrlOwners}${ownerId}`, {
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message || error.response.data.error.message);
    } else {
      throw error;
    }
  }
});

// Slice
const ownersSlice = createSlice({
  name: 'owners',
  initialState: {
    ownerLogged: null,
    ownerIsLogging: false,
    errorOwnerLogged: null,
    newOwner: null,
    errorNewOwner: null,
    successNewOwner: null,
    newOwnerIsLoading: false,
    ownerVerify: null,
    isVerifying: false,
    errorVerify: null,
    expiredEmail: null,
    ownerNewVerify: null,
    isNewVerifying: false,
    errorNewVerify: null,
    editOwner: null,
    editOwnerSuccessMessage: null,
    isEditingOwner: false,
    editOwnerError: null,
    editEmailOwner: null,
    editEmailOwnerIsLoading: false,
    editEmailOwnerSuccess: null,
    editEmailOwnerError: null,
    deletedOwner: null,
    deleteOwnerSuccessMessage: null,
    isDeletingOwner: false,
    deleteOwnerError: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOwnerById.pending, (state) => {
        state.ownerIsLogging = true;
      })
      .addCase(getOwnerById.fulfilled, (state, action) => {
        state.ownerLogged = action.payload;
        state.ownerIsLogging = false;
      })
      .addCase(getOwnerById.rejected, (state, action) => {
        state.ownerIsLogging = false;
        state.errorOwnerLogged = action.payload;
      })
      .addCase(registerOwner.pending, (state) => {
        state.newOwnerIsLoading = true;
      })
      .addCase(registerOwner.fulfilled, (state, action) => {
        state.newOwner = action.payload;
        state.newOwnerIsLoading = false;
        state.successNewOwner = action.payload.message;
      })
      .addCase(registerOwner.rejected, (state, action) => {
        state.newOwnerIsLoading = false;
        state.errorNewOwner = action.payload;
      })
      .addCase(verifyOwner.pending, (state) => {
        state.isVerifying = true;
      })
      .addCase(verifyOwner.fulfilled, (state, action) => {
        state.ownerVerify = action.payload;
        state.isVerifying = false;
      })
      .addCase(verifyOwner.rejected, (state, action) => {
        state.errorVerify = action.payload.message;
        state.isVerifying = false;
        if (action.payload.expiredEmail) {
          state.expiredEmail = action.payload.expiredEmail;
        }
      })
      .addCase(resendVerifyOwner.pending, (state) => {
        state.isNewVerifying = true;
      })
      .addCase(resendVerifyOwner.fulfilled, (state, action) => {
        state.ownerNewVerify = action.payload;
        state.isNewVerifying = false;
      })
      .addCase(resendVerifyOwner.rejected, (state, action) => {
        state.errorNewVerify = action.payload;
        state.isNewVerifying = false;
      })
      .addCase(editOwner.pending, (state) => {
        state.isEditingOwner = true;
      })
      .addCase(editOwner.fulfilled, (state, action) => {
        state.editOwner = action.payload;
        state.editOwnerSuccessMessage = action.payload.message;
        state.isEditingOwner = false;
      })
      .addCase(editOwner.rejected, (state, action) => {
        state.editOwnerError = action.payload;
        state.isEditingOwner = false;
      })
      .addCase(editEmailOwner.pending, (state) => {
        state.editEmailOwnerIsLoading = true;
      })
      .addCase(editEmailOwner.fulfilled, (state, action) => {
        state.editEmailOwner = action.payload;
        state.editEmailOwnerSuccess = action.payload.message;
        state.editEmailOwnerIsLoading = false;
      })
      .addCase(editEmailOwner.rejected, (state, action) => {
        state.editEmailOwnerError = action.payload;
        state.editEmailOwnerIsLoading = false;
      })
      .addCase(deleteOwner.pending, (state) => {
        state.isDeletingOwner = true;
      })
      .addCase(deleteOwner.fulfilled, (state, action) => {
        state.deletedOwner = action.payload;
        state.deleteOwnerSuccessMessage = action.payload.message;
        state.isDeletingOwner = false;
      })
      .addCase(deleteOwner.rejected, (state, action) => {
        state.deleteOwnerError = action.payload;
        state.isDeletingOwner = false;
      });
  }
});

export default ownersSlice.reducer;
