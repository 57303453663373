// src/components/DragAndDropModal.jsx
import React, { useState, useEffect } from 'react';
import Modal from './modal/Modal'; // Assicurati che il percorso sia corretto
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useDispatch } from 'react-redux';
import { updateMenuThunk } from '../redux/menusSlice';
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const DragAndDropModal = ({
    isOpen,
    onRequestClose,
    owner,
    menu,
    refreshMenuOwner,
    refreshAllMenusOwner
}) => {
    const dispatch = useDispatch();
    const [currentMenu, setCurrentMenu] = useState(menu);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [collapsedCategories, setCollapsedCategories] = useState({});
    const [draggingItemId, setDraggingItemId] = useState(null); // Stato per tracciare il trascinamento

    useEffect(() => {
        if (isOpen) {
            setError(false);
            setSuccess(false);
            setLoading(false);
            // Inizializza lo stato di collapse per le categorie
            if (menu) {
                const initialCollapsed = {};
                menu.menu.menuCategory.forEach(category => {
                    initialCollapsed[category._id] = false; // false significa espanso
                });
                setCollapsedCategories(initialCollapsed);
            }
        }
    }, [isOpen, menu]);

    useEffect(() => {
        if (menu) {
            // Clona profondamente e aggiungi valori predefiniti per 'color'
            const clonedMenu = JSON.parse(JSON.stringify(menu));

            clonedMenu.menu.menuCategory = clonedMenu.menu.menuCategory.map(category => ({
                ...category,
                color: category.color, // Colore predefinito se mancante
                menuItems: category.menuItems.map(item => ({
                    ...item,
                    color: item.color, // Colore predefinito se mancante
                })),
            }));

            setCurrentMenu(clonedMenu);
        }
    }, [menu]);

    const toggleCategory = (categoryId) => {
        setCollapsedCategories(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));
    };

    const handleOnDragEnd = (result) => {
        setDraggingItemId(null); // Reset dello stato di trascinamento

        const { destination, source, draggableId, type } = result;

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        if (type === 'CATEGORY') {
            // Riordina le categorie
            const newCategories = Array.from(currentMenu.menu.menuCategory);
            const [movedCategory] = newCategories.splice(source.index, 1);
            newCategories.splice(destination.index, 0, movedCategory);

            setCurrentMenu({
                ...currentMenu,
                menu: {
                    ...currentMenu.menu,
                    menuCategory: newCategories,
                },
            });

        } else if (type === 'ITEM') {
            // Riordina gli item
            const sourceCategoryId = source.droppableId;
            const destCategoryId = destination.droppableId;

            const sourceCategory = currentMenu.menu.menuCategory.find(cat => cat._id === sourceCategoryId);
            const destCategory = currentMenu.menu.menuCategory.find(cat => cat._id === destCategoryId);

            if (!sourceCategory || !destCategory) {
                console.error("Categoria di origine o destinazione non trovata");
                return;
            }

            const sourceItems = Array.from(sourceCategory.menuItems);
            const [movedItem] = sourceItems.splice(source.index, 1);

            if (sourceCategoryId === destCategoryId) {
                // Sposta all'interno della stessa categoria
                sourceItems.splice(destination.index, 0, movedItem);
                const newCategories = currentMenu.menu.menuCategory.map(cat => {
                    if (cat._id === sourceCategoryId) {
                        return { ...cat, menuItems: sourceItems };
                    }
                    return cat;
                });

                setCurrentMenu({
                    ...currentMenu,
                    menu: {
                        ...currentMenu.menu,
                        menuCategory: newCategories,
                    },
                });

            } else {
                // Sposta tra categorie diverse
                const destItems = Array.from(destCategory.menuItems);
                destItems.splice(destination.index, 0, movedItem);
                const newCategories = currentMenu.menu.menuCategory.map(cat => {
                    if (cat._id === sourceCategoryId) {
                        return { ...cat, menuItems: sourceItems };
                    }
                    if (cat._id === destCategoryId) {
                        return { ...cat, menuItems: destItems };
                    }
                    return cat;
                });

                setCurrentMenu({
                    ...currentMenu,
                    menu: {
                        ...currentMenu.menu,
                        menuCategory: newCategories,
                    },
                });

            }
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {

            const response = await dispatch(updateMenuThunk({ menuId: menu._id, updatedMenu: currentMenu }));

            if (updateMenuThunk.fulfilled.match(response)) {
                setLoading(false);
                setSuccess(true);
                if (error) {
                    setError(false);
                }
                await refreshAllMenusOwner(owner._id);
                await refreshMenuOwner(owner._id);

                onRequestClose();
            } else {
                setLoading(false);
                setError(true);
            }
        } catch (error) {
            console.error('Errore nell\'aggiornamento del menu:', error);
            setLoading(false);
            setError(true);
        }
    };

    // Gestisci l'inizio del trascinamento per impostare l'ID dell'elemento trascinato
    const handleDragStart = (start) => {
        if (start.type === 'ITEM') {
            setDraggingItemId(start.draggableId);
        }
    };


    return (
        <>
            {isOpen && (
                <Modal
                    closeFunction={onRequestClose}
                    title="Riorganizza il Menu"
                    success={success}
                    successMessage="Menu salvato con successo!"
                    error={error}
                    errorMessage="Si è verificato un errore nel salvataggio del menu. Per favore, riprova."
                    isLoading={loading}
                    loading={loading}
                    // Aggiungi handleSubmit per il salvataggio
                    handleSubmit={handleSave}
                >
                    {/* Contesto Drag-and-Drop */}
                    <div className="max-h-[70vh] overflow-y-auto">
                        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleDragStart}>
                            <Droppable droppableId="all-categories" type="CATEGORY">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {currentMenu.menu.menuCategory.map((category, index) => (
                                            <Draggable key={category._id} draggableId={category._id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        className="mb-2" // Riduzione del margine
                                                    >
                                                        {/* Struttura Orizzontale per Categoria e Piatti */}
                                                        <div className="flex items-start bg-gray-50 p-2 rounded-lg border">
                                                            {/* Intestazione della Categoria con Drag Handle e Toggle */}
                                                            <div
                                                                className="flex flex-col justify-start mr-4 flex-none w-4/12"
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {/* Toggle Icon */}
                                                                <button
                                                                    className="mb-2 focus:outline-none"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation(); // Previene l'attivazione del drag quando si clicca sul toggle
                                                                        toggleCategory(category._id);
                                                                    }}
                                                                >
                                                                    {collapsedCategories[category._id] ? (
                                                                        <ChevronRightIcon className="w-5 h-5" />
                                                                    ) : (
                                                                        <ChevronDownIcon className="w-5 h-5" />
                                                                    )}
                                                                </button>
                                                                {/* Nome della Categoria */}
                                                                <h3
                                                                    className="text-lg font-semibold truncate"
                                                                    title={category.translations.find(t => t.language === 'ita')?.name}
                                                                >
                                                                    {category.translations.find(t => t.language === 'ita')?.name}
                                                                </h3>
                                                            </div>

                                                            {/* Droppable per gli Item della Categoria */}
                                                            {!collapsedCategories[category._id] && (
                                                                <Droppable droppableId={category._id} type="ITEM">
                                                                    {(provided) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            className="flex flex-col space-y-1 flex-grow pl-2 min-w-0"
                                                                        >
                                                                            {category.menuItems.map((item, itemIndex) => (
                                                                                <Draggable key={item._id} draggableId={item._id} index={itemIndex}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            className={`p-2 border border-green-200 bg-green-100 rounded shadow-sm flex items-center transition-all duration-200 ${
                                                                                                draggingItemId && draggingItemId !== item._id
                                                                                                    ? 'h-6 opacity-75'
                                                                                                    : 'h-6 opacity-100'
                                                                                            }`}
                                                                                        >
                                                                                            {/* Wrapper per mantenere l'altezza coerente */}
                                                                                            <div className="w-full flex items-center min-w-0">
                                                                                                <h4
                                                                                                    className="text-sm font-medium truncate"
                                                                                                    title={item.translations.find(t => t.language === 'ita')?.name}
                                                                                                >
                                                                                                    {item.translations.find(t => t.language === 'ita')?.name}
                                                                                                </h4>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>
                                                                            ))}
                                                                            {provided.placeholder && (
                                                                                <div className="h-4" />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default DragAndDropModal;
