import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const AuthSuccess = () => {
    const navigate = useNavigate();
    useEffect(() => {
        console.log("Full URL:", window.location.href);

        let token = localStorage.getItem('userLoggedIn');

        if (!token) {
            const url = window.location.href;
            const tokenParam = url.split('token=')[1];
            token = tokenParam ? tokenParam.split('&')[0] : null;
            console.log('token url', token)
        }

        if (token) {
          console.log('ok token')
          token = token.replace(/\"/g, '');
          console.log(token)
          localStorage.setItem('userLoggedIn', JSON.stringify(token));
          navigate('/dashboard');
        } else {
          console.log('no token')
            navigate('/');
        }
    }, []);


  return (
    <div className='font-primary text-5xl font-weight: 900 py-2 sm:text-8xl'>Checking...</div>
  )
}

export default AuthSuccess