import React, { useState, useEffect } from 'react';
import Modal from './modal/Modal';
import Buttons from './modal/Buttons';
import { useDispatch } from 'react-redux';
import { create } from '../redux/menusSlice';

const AdvancedOptions = ({
  closeShowAdvancedOptions,
  owner,
  refreshAllMenusOwner,
}) => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setMessage('');
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setMessage('Please select a file.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const fileContent = e.target.result;
        const jsonContent = JSON.parse(fileContent);

        // Dispatch the create action with the file content
        const response = await dispatch(create(jsonContent));

        if (create.fulfilled.match(response)) {
          // Success: update menus and close modal
          await refreshAllMenusOwner(owner._id);
          closeShowAdvancedOptions();
        } else {
          setMessage('Error uploading the file. Please try again.');
        }
      } catch (error) {
        console.error('Error updating the menu:', error);
        setMessage('Error uploading menu: ' + error.message);
      }
    };

    reader.readAsText(file); // Read file as text
  };

  return (
    <Modal closeFunction={closeShowAdvancedOptions} title="Operazioni Avanzate">
      {/* Section for uploading menu */}
      <div className="mb-6">
        <h2 className="text-lg font-bold mb-4">Importa Menu</h2>
        <div className="mb-4">
          <input
            type="file"
            accept=".json"
            onChange={handleFileChange}
            className="border p-2 w-full"
          />
        </div>
        <div className="flex justify-start mt-4">
          <Buttons type="green" onClick={handleFileUpload}>
            Upload JSON
          </Buttons>
        </div>
        {message && <p className="mt-2 text-center text-red-600">{message}</p>}
      </div>

      <hr className="border-gray-400 mt-4 mb-4 w-full" />

    </Modal>
  );
};

export default AdvancedOptions;
