import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { SketchPicker } from 'react-color';


const ColorSection = ({ handleColorChange, deleteColor, color, prevColor }) => {

    const [showPicker, setShowPicker] = useState(false)
    const handleColorPickerOpen = () => setShowPicker(true);
    const handleColorPickerClose = () => setShowPicker(false);

    return (

        <>

            <div className='mt-6 flex flex-row items-center gap-2 my-1'>
                <span className='font-bold'>Colore:</span>
                <div
                    className="w-5 h-5 rounded-lg"
                    style={{ backgroundColor: `${color}` }}
                />
                <button
                    className="p-1 text-black rounded-lg"
                    type='button'
                    onClick={(e) => {
                        e.stopPropagation();
                        handleColorPickerOpen();
                    }}
                >
                    <FiEdit />
                </button>
            </div>
            {showPicker && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-5 rounded">
                                <SketchPicker color={color}  onChangeComplete={handleColorChange} />
                                <button 
                                    type= 'button'
                                    className="mt-2 p-1 w-full green-background text-white rounded"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleColorPickerClose();
                                    }}
                                >
                                    Chiudi
                                </button>

                                <button 
                                    type= 'button'
                                    className="mt-2 p-1 w-full bg-red-500 text-white rounded"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteColor();
                                    }}
                                >
                                    Rimuovi
                                </button>
                            </div>
                        </div>
                    )}

        </>
    )
}

export default ColorSection;
