// src/redux/ingredientsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api'; // Importa l'istanza personalizzata

const apiUrlIngredients = `/ingredients/owner`;
const apiUrlIngredientCreation = `/ingredients/create`;
const apiUrlIngredientUpdate = `/ingredients`;

// Async Thunks
export const createIngredient = createAsyncThunk(
  'ingredients/create',
  async (ingredientData, { rejectWithValue }) => {
    try {
      const response = await api.post(`${apiUrlIngredientCreation}`, ingredientData, {
      });
      return response.data; // Assicurati che ritorni l'oggetto ingrediente completo
    } catch (error) {
      console.error('Error creating ingredient', error);
      return rejectWithValue(error.response?.data?.message || 'Error creating ingredient');
    }
  }
);

export const updateIngredient = createAsyncThunk(
  'ingredients/update',
  async ({ ingredientId, dataToUpdate }, { rejectWithValue }) => {
    try {
      const response = await api.patch(`${apiUrlIngredientUpdate}/${ingredientId}/owner/update`, dataToUpdate, {
      });
      return response.data; // Assicurati che ritorni l'oggetto ingrediente aggiornato
    } catch (error) {
      console.error('Error updating ingredient', error);
      return rejectWithValue(error.response?.data?.message || 'Error updating ingredient');
    }
  }
);

export const getIngredients = createAsyncThunk(
  'ingredients/owner',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${apiUrlIngredients}`, {
      });
      return response.data;
    } catch (error) {
      console.error('Error getting owner ingredients', error);
      return rejectWithValue(error.response?.data?.message || 'Error getting owner ingredients');
    }
  }
);

export const getCustomIngredients = createAsyncThunk(
  'ingredients/custom/owner',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${apiUrlIngredientUpdate}/custom/owner`, {
      });
      return response.data;
    } catch (error) {
      console.error('Error getting owner custom ingredients', error);
      return rejectWithValue(error.response?.data?.message || 'Error getting owner custom ingredients');
    }
  }
);

export const getAllergens = createAsyncThunk(
  'ingredients/allergens',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${process.env.REACT_APP_SERVER_BASE_URL}/allergenics/ita`, {
      });
      return response.data;
    } catch (error) {
      console.error('Error getting allergens', error);
      return rejectWithValue(error.response?.data?.message || 'Error getting allergens');
    }
  }
);

// New Async Thunk for Deleting an Ingredient
export const deleteIngredient = createAsyncThunk(
  'ingredients/delete',
  async (ingredientId, { rejectWithValue }) => {
    try {
      const response = await api.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/ingredients/${ingredientId}`, {
      });
      return { ingredientId, message: response.data.message };
    } catch (error) {
      console.error('Error deleting ingredient', error);
      return rejectWithValue(error.response?.data?.message || 'Error deleting ingredient');
    }
  }
);

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState: {
    ingredientsOwner: [],
    ingredientsOwnerIsLoading: false,
    ingredientsOwnerError: null,
    ingredientsOwnerSuccess: null,
    ingredientsCustomOwner: [],
    ingredientsCustomOwnerIsLoading: false,
    ingredientsCustomOwnerError: null,
    ingredientsCustomOwnerSuccess: null,
    ingredientCreation: null,
    ingredientCreationIsLoading: false,
    ingredientCreationError: null,
    ingredientCreationSuccess: null,
    ingredientUpdate: null,
    ingredientUpdateIsLoading: false,
    ingredientUpdateError: null,
    ingredientUpdateSuccess: null,
    allergens: [],
    allergensIsLoading: false,
    allergensError: null,
    allergensSuccess: null,
    ingredientDeletion: null,
    ingredientDeletionIsLoading: false,
    ingredientDeletionError: null,
    ingredientDeletionSuccess: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getIngredients
      .addCase(getIngredients.pending, (state) => {
        state.ingredientsOwnerIsLoading = true;
        state.ingredientsOwnerError = null;
        state.ingredientsOwnerSuccess = null;
      })
      .addCase(getIngredients.rejected, (state, action) => {
        state.ingredientsOwnerError = action.payload || 'Error getting owner ingredients';
        state.ingredientsOwnerIsLoading = false;
      })
      .addCase(getIngredients.fulfilled, (state, action) => {
        state.ingredientsOwner = action.payload.ingredients || []; // Assicurati che sia un array
        state.ingredientsOwnerSuccess = action.payload.message || 'Ingredients loaded successfully';
        state.ingredientsOwnerIsLoading = false;
      })
      
      // getCustomIngredients
      .addCase(getCustomIngredients.pending, (state) => {
        state.ingredientsCustomOwnerIsLoading = true;
        state.ingredientsCustomOwnerError = null;
        state.ingredientsCustomOwnerSuccess = null;
      })
      .addCase(getCustomIngredients.rejected, (state, action) => {
        state.ingredientsCustomOwnerError = action.payload || 'Error getting owner custom ingredients';
        state.ingredientsCustomOwnerIsLoading = false;
      })
      .addCase(getCustomIngredients.fulfilled, (state, action) => {
        state.ingredientsCustomOwner = action.payload.ingredients || []; // Assegna solo l'array ingredients
        state.ingredientsCustomOwnerSuccess = action.payload.message || 'Custom ingredients loaded successfully';
        state.ingredientsCustomOwnerIsLoading = false;
      })
      
      // createIngredient
      .addCase(createIngredient.pending, (state) => {
        state.ingredientCreationIsLoading = true;
        state.ingredientCreationError = null;
        state.ingredientCreationSuccess = null;
      })
      .addCase(createIngredient.rejected, (state, action) => {
        state.ingredientCreationError = action.payload || 'Error creating ingredient';
        state.ingredientCreationIsLoading = false;
      })
      .addCase(createIngredient.fulfilled, (state, action) => {
        // Assicurati che action.payload contenga l'ingrediente completo
        state.ingredientCreation = action.payload;
        state.ingredientCreationSuccess = action.payload.message || 'Ingredient created successfully';
        state.ingredientCreationIsLoading = false;
        // Non aggiungere manualmente l'ingrediente all'array
        // La componente dovrebbe gestire il ricalcolo tramite getCustomIngredients
      })
      
      // updateIngredient
      .addCase(updateIngredient.pending, (state) => {
        state.ingredientUpdateIsLoading = true;
        state.ingredientUpdateError = null;
        state.ingredientUpdateSuccess = null;
      })
      .addCase(updateIngredient.rejected, (state, action) => {
        state.ingredientUpdateError = action.payload || 'Error updating ingredient';
        state.ingredientUpdateIsLoading = false;
      })
      .addCase(updateIngredient.fulfilled, (state, action) => {
        const index = state.ingredientsCustomOwner.findIndex(ingredient => ingredient._id === action.payload._id);
        if (index !== -1) {
          state.ingredientsCustomOwner[index] = action.payload; // Aggiorna l'ingrediente nell'array
        }
        state.ingredientUpdate = action.payload;
        state.ingredientUpdateSuccess = action.payload.message || 'Ingredient updated successfully';
        state.ingredientUpdateIsLoading = false;
      })
      
      // getAllergens
      .addCase(getAllergens.pending, (state) => {
        state.allergensIsLoading = true;
        state.allergensError = null;
        state.allergensSuccess = null;
      })
      .addCase(getAllergens.rejected, (state, action) => {
        state.allergensError = action.payload || 'Error getting allergens';
        state.allergensIsLoading = false;
      })
      .addCase(getAllergens.fulfilled, (state, action) => {
        state.allergens = action.payload.allergens || []; // Assicurati che sia un array
        state.allergensSuccess = 'Allergens loaded successfully';
        state.allergensIsLoading = false;
      })

      // deleteIngredient
      .addCase(deleteIngredient.pending, (state) => {
        state.ingredientDeletionIsLoading = true;
        state.ingredientDeletionError = null;
        state.ingredientDeletionSuccess = null;
      })
      .addCase(deleteIngredient.rejected, (state, action) => {
        state.ingredientDeletionError = action.payload || 'Error deleting ingredient';
        state.ingredientDeletionIsLoading = false;
      })
      .addCase(deleteIngredient.fulfilled, (state, action) => {
        const { ingredientId, message } = action.payload;
        // Remove from ingredientsOwner
        state.ingredientsOwner = state.ingredientsOwner.filter(
          (ingredient) => ingredient._id !== ingredientId
        );
        // Remove from ingredientsCustomOwner
        state.ingredientsCustomOwner = state.ingredientsCustomOwner.filter(
          (ingredient) => ingredient._id !== ingredientId
        );
        state.ingredientDeletion = ingredientId;
        state.ingredientDeletionSuccess = message || 'Ingredient deleted successfully';
        state.ingredientDeletionIsLoading = false;
      });
  }
});

export default ingredientsSlice.reducer;
