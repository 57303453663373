// src/components/CreateIngredient.jsx

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getCustomIngredients, 
  getIngredients,
  createIngredient, 
  updateIngredient,
  deleteIngredient
} from '../redux/ingredientsSlice';
import { FiEdit2, FiSave, FiX } from 'react-icons/fi';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';

// Import delle bandiere
import itaFlag from '../asset/ita.jpg';
import engFlag from '../asset/eng.jpg';
import fraFlag from '../asset/fra.jpg';
import deuFlag from '../asset/deu.jpg';
import spaFlag from '../asset/spa.jpg';

const CreateIngredient = ({
  showIngredientCreation,
  setShowIngredientCreation,
  menuDefaultLanguage,
  ingredients,
  translatedIngredients
}) => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [newIngredient, setNewIngredient] = useState({
    ita: '',
    eng: '',
    fra: '',
    deu: '',
    esp: ''
  });
  const [editingIngredientId, setEditingIngredientId] = useState(null);
  const [editedIngredient, setEditedIngredient] = useState({
    ita: '',
    eng: '',
    fra: '',
    deu: '',
    esp: ''
  });
  const [formError, setFormError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const [smallScreen, setSmallScreen] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 768;
    }
    return false;
  });

  const languages = [
    { code: 'ita', name: 'Italiano', flag: itaFlag },
    { code: 'eng', name: 'Inglese', flag: engFlag },
    { code: 'fra', name: 'Francese', flag: fraFlag },
    { code: 'deu', name: 'Tedesco', flag: deuFlag },
    { code: 'esp', name: 'Spagnolo', flag: spaFlag },
  ];

  const {
    ingredientsCustomOwner,
    ingredientsCustomOwnerIsLoading,
    ingredientsCustomOwnerError,
    ingredientCreationIsLoading,
    ingredientCreationError,
    ingredientCreationSuccess,
    ingredientUpdateIsLoading,
    ingredientUpdateError,
    ingredientUpdateSuccess,
    ingredientDeletionIsLoading,
    ingredientDeletionError,
    ingredientDeletionSuccess
  } = useSelector((state) => state.ingredients);

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (showIngredientCreation) {
      dispatch(getCustomIngredients());
    }
  }, [dispatch, showIngredientCreation]);

  useEffect(() => {
    if (ingredientCreationSuccess) {
      dispatch(getCustomIngredients());
      setIsAdding(false);
      setNewIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });

      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = 0;
      }
    }
  }, [ingredientCreationSuccess, dispatch]);

  useEffect(() => {
    if (ingredientUpdateSuccess) {
      dispatch(getCustomIngredients());
      setEditingIngredientId(null);
      setEditedIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });

      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = 0;
      }
    }
  }, [ingredientUpdateSuccess, dispatch]);

  useEffect(() => {
    if (ingredientDeletionSuccess) {
      dispatch(getCustomIngredients());
      dispatch(getIngredients());
      setDeleteSuccess(ingredientDeletionSuccess);
      setDeleteError('');
      setTimeout(() => setDeleteSuccess(''), 3000);
    }
  }, [ingredientDeletionSuccess, dispatch]);

  useEffect(() => {
    if (ingredientDeletionError) {
      setDeleteError(ingredientDeletionError);
      setDeleteSuccess('');
      setTimeout(() => setDeleteError(''), 3000);
    }
  }, [ingredientDeletionError]);

  useEffect(() => {
    if (showIngredientCreation) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }
  }, [showIngredientCreation]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (showIngredientCreation) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [showIngredientCreation]);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Chiamata iniziale per impostare lo stato corretto

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeModal = () => {
    setShowIngredientCreation(false);
    setIsAdding(false);
    setNewIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });
    setEditingIngredientId(null);
    setEditedIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });
    setFormError('');
    setDeleteError('');
    setDeleteSuccess('');
    setSearchQuery('');
    setDebouncedSearch('');
  };

  const handleNewIngredientChange = (e) => {
    const { name, value } = e.target;
    setNewIngredient(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddIngredient = (e) => {
    e.preventDefault();

    const filteredTranslations = languages.map(lang => ({
      language: lang.code,
      translatedName: newIngredient[lang.code]
    })).filter(translation => translation.translatedName.trim() !== '');

    if (filteredTranslations.length === 0) {
      setFormError('Per favore, inserisci almeno una traduzione.');
      return;
    }

    setFormError('');

    const payload = {
      verified: false,
      translations: filteredTranslations
    };

    dispatch(createIngredient(payload)).then(() => {
      dispatch(getIngredients());
    });
  };

  const startEditing = (ingredient) => {
    setEditingIngredientId(ingredient._id);
    const translations = ingredient.translations;
    setEditedIngredient({
      ita: translations.find(t => t.language === 'ita')?.translatedName || '',
      eng: translations.find(t => t.language === 'eng')?.translatedName || '',
      fra: translations.find(t => t.language === 'fra')?.translatedName || '',
      deu: translations.find(t => t.language === 'deu')?.translatedName || '',
      esp: translations.find(t => t.language === 'esp')?.translatedName || ''
    });
  };

  const handleEditedIngredientChange = (e) => {
    const { name, value } = e.target;
    setEditedIngredient(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdateIngredient = (e) => {
    e.preventDefault();
    const payload = {
      ingredientId: editingIngredientId,
      dataToUpdate: {
        verified: false,
        translations: languages.map(lang => ({
          language: lang.code,
          translatedName: editedIngredient[lang.code]
        }))
      }
    };
    dispatch(updateIngredient(payload)).then(() => {
      dispatch(getIngredients());
    });
  };

  const handleDeleteIngredient = (ingredientId) => {
    const confirmDelete = window.confirm('Sei sicuro di voler eliminare questo ingrediente?');
    if (confirmDelete) {
      dispatch(deleteIngredient(ingredientId));
    }
  };

  const isSaveDisabled = !languages.some(lang => newIngredient[lang.code].trim());

  const debouncedSetSearch = useMemo(
    () => debounce((query) => setDebouncedSearch(query), 300),
    []
  );

  useEffect(() => {
    debouncedSetSearch(searchQuery);
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [searchQuery, debouncedSetSearch]);

  const filteredIngredients = useMemo(() => {
    if (!debouncedSearch.trim()) return ingredientsCustomOwner;

    return ingredientsCustomOwner.filter((ingredient) => {
      const translations = ingredient.translations || [];
      return translations.some((t) =>
        t.translatedName.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
    });
  }, [ingredientsCustomOwner, debouncedSearch]);

  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} className="bg-yellow-200">{part}</span> : part
    );
  };

  const handleLanguageChange = (direction) => {
    if (direction === 'prev') {
      setCurrentLanguageIndex((currentLanguageIndex - 1 + languages.length) % languages.length);
    } else if (direction === 'next') {
      setCurrentLanguageIndex((currentLanguageIndex + 1) % languages.length);
    }
  };

  return (
    <>
      {showIngredientCreation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className='-mt-10 font-primary text-black bg-white border lg:w-8/12 h-[90vh] w-11/12 rounded-lg pb-5 flex flex-col overflow-hidden'>
            {/* Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold">Ingredienti Personalizzati</h3>
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            {/* Content with Search Bar */}
            <h3 className="text-gray-500 text-base mb-2 px-5">Crea o modifica i tuoi ingredienti personalizzati, da utilizzare nel menu</h3>

            <div className="p-4">
              {/* Search Bar */}
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Cerca ingredienti..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Navigazione tra le lingue per schermi piccoli */}
              {smallScreen && (
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() => handleLanguageChange('prev')}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded-l"
                  >
                    Precedente
                  </button>
                  <button
                    onClick={() => handleLanguageChange('next')}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded-r"
                  >
                    Successivo
                  </button>
                </div>
              )}

              <div className="relative overflow-x-auto">
                <table className="w-full bg-white border border-gray-200 table-fixed">
                  <thead>
                    <tr>
                      {smallScreen ? (
                        <>
                          <th className="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 w-8/12">
                            <div className="flex items-center justify-center">
                              <img src={languages[currentLanguageIndex].flag} alt={languages[currentLanguageIndex].name} className="w-6 h-4 mr-2" />
                              <span className="text-base font-normal">{languages[currentLanguageIndex].name}</span>
                            </div>
                          </th>
                          <th className="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 w-4/12">
                            Azioni
                          </th>
                        </>
                      ) : (
                        <>
                          {languages.map((lang) => (
                            <th key={lang.code} className="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 w-2/12">
                              <div className="flex items-center justify-center">
                                <img src={lang.flag} alt={lang.name} className="w-6 h-4 mr-2" />
                                <span className="text-base font-normal">{lang.name}</span>
                              </div>
                            </th>
                          ))}
                          <th className="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 w-2/12">
                            Azioni
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            {/* Body */}
            <div className="flex-1 p-4 overflow-y-auto" ref={scrollableDivRef}>
              {/* Messaggi di errore e successo */}
              {ingredientCreationError && (
                <div className="mb-4 text-red-500">
                  {ingredientCreationError}
                </div>
              )}

              {ingredientUpdateError && (
                <div className="mb-4 text-red-500">
                  {ingredientUpdateError}
                </div>
              )}

              {deleteError && (
                <div className="mb-4 text-red-500">
                  {deleteError}
                </div>
              )}

              {formError && (
                <div className="mb-4 text-red-500">
                  {formError}
                </div>
              )}

              {/* Stato di Caricamento */}
              {ingredientsCustomOwnerIsLoading ? (
                <div className="flex justify-center items-center h-full">
                  <div className="text-gray-500">Caricamento ingredienti...</div>
                </div>
              ) : ingredientsCustomOwnerError ? (
                <div className="flex justify-center items-center h-full">
                  <div className="text-red-500">Errore: {ingredientsCustomOwnerError}</div>
                </div>
              ) : (
                <>
                  {/* Tabella degli Ingredienti */}
                  <div className="relative overflow-x-auto">
                    <table className="w-full bg-white border border-gray-200 table-fixed">
                      <thead>
                        {/* Riga per aggiungere un nuovo ingrediente */}
                        {isAdding && (
                          <tr className="bg-gray-50">
                            {smallScreen ? (
                              <>
                                <td className="px-4 py-4 border-b border-gray-200 w-8/12">
                                  <input
                                    type="text"
                                    name={languages[currentLanguageIndex].code}
                                    value={newIngredient[languages[currentLanguageIndex].code]}
                                    onChange={handleNewIngredientChange}
                                    className="w-full border border-gray-300 text-center rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                                  />
                                </td>
                                <td className="px-4 py-4 border-b border-gray-200 text-center w-4/12">
                                  <div className="flex justify-center space-x-2">
                                    <button
                                      onClick={handleAddIngredient}
                                      disabled={ingredientCreationIsLoading}
                                      className="green-background hover:bg-green-600 text-center text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                    >
                                      <FiSave className="w-4 h-4" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsAdding(false);
                                        setNewIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });
                                        setFormError('');
                                      }}
                                      className="bg-gray-600 hover:bg-gray-400 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                    >
                                      <FiX className="w-4 h-4" />
                                    </button>
                                  </div>
                                </td>
                              </>
                            ) : (
                              <>
                                {languages.map((lang) => (
                                  <td key={lang.code} className="px-4 py-4 border-b border-gray-200 w-2/12">
                                    <input
                                      type="text"
                                      name={lang.code}
                                      value={newIngredient[lang.code]}
                                      onChange={handleNewIngredientChange}
                                      className="w-full border border-gray-300 text-center rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                                    />
                                  </td>
                                ))}
                                <td className="px-4 py-4 border-b border-gray-200 text-center w-2/12">
                                  <div className="flex justify-center space-x-2">
                                    <button
                                      onClick={handleAddIngredient}
                                      disabled={ingredientCreationIsLoading}
                                      className="green-background hover:bg-green-600 text-center text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                    >
                                      <FiSave className="w-4 h-4" />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsAdding(false);
                                        setNewIngredient({ ita: '', eng: '', fra: '', deu: '', esp: '' });
                                        setFormError('');
                                      }}
                                      className="bg-gray-600 hover:bg-gray-400 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                    >
                                      <FiX className="w-4 h-4" />
                                    </button>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {/* Righe esistenti degli ingredienti */}
                        {filteredIngredients.map((ingredient) => {
                          const translations = ingredient.translations || [];

                          const getTranslation = (lang) => {
                            const translation = translations.find(t => t.language === lang);
                            return translation ? translation.translatedName : '';
                          };

                          if (editingIngredientId === ingredient._id) {
                            return (
                              <tr key={ingredient._id} className="hover:bg-gray-50">
                                {smallScreen ? (
                                  <>
                                    <td className="px-4 py-4 border-b border-gray-200 w-8/12">
                                      <input
                                        type="text"
                                        name={languages[currentLanguageIndex].code}
                                        value={editedIngredient[languages[currentLanguageIndex].code]}
                                        onChange={handleEditedIngredientChange}
                                        required
                                        className="w-full border text-center border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                                      />
                                    </td>
                                    <td className="px-4 py-4 border-b border-gray-200 text-center w-4/12">
                                      <div className="flex justify-center space-x-2">
                                        <button
                                          onClick={handleUpdateIngredient}
                                          disabled={ingredientUpdateIsLoading}
                                          className="green-background hover:bg-green-600 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        >
                                          <FiSave className="w-4 h-4" />
                                        </button>
                                        <button
                                          onClick={() => setEditingIngredientId(null)}
                                          className="bg-gray-600 hover:bg-gray-400 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        >
                                          <FiX className="w-4 h-4" />
                                        </button>
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    {languages.map((lang) => (
                                      <td key={lang.code} className="px-4 py-4 border-b border-gray-200 w-2/12">
                                        <input
                                          type="text"
                                          name={lang.code}
                                          value={editedIngredient[lang.code]}
                                          onChange={handleEditedIngredientChange}
                                          required
                                          className="w-full border text-center border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                      </td>
                                    ))}
                                    <td className="px-4 py-4 border-b border-gray-200 text-center w-2/12">
                                      <div className="flex justify-center space-x-2">
                                        <button
                                          onClick={handleUpdateIngredient}
                                          disabled={ingredientUpdateIsLoading}
                                          className="green-background hover:bg-green-600 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        >
                                          <FiSave className="w-4 h-4" />
                                        </button>
                                        <button
                                          onClick={() => setEditingIngredientId(null)}
                                          className="bg-gray-600 hover:bg-gray-400 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        >
                                          <FiX className="w-4 h-4" />
                                        </button>
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          }

                          return (
                            <tr key={ingredient._id} className="hover:bg-gray-50">
                              {smallScreen ? (
                                <>
                                  <td className="px-4 py-1 border-b border-gray-200 text-center w-8/12">
                                    {highlightText(getTranslation(languages[currentLanguageIndex].code), debouncedSearch)}
                                  </td>
                                  <td className="px-4 py-1 border-b border-gray-200 text-center w-4/12">
                                    <div className="flex justify-center space-x-2">
                                      <button
                                        className="green-background hover:bg-green-600 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={() => startEditing(ingredient)}
                                      >
                                        <FiEdit2 className="w-4 h-4" />
                                      </button>
                                      <button
                                        className="bg-red-600 hover:bg-red-500 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={() => handleDeleteIngredient(ingredient._id)}
                                        disabled={ingredientDeletionIsLoading}
                                        title="Elimina Ingrediente"
                                      >
                                        {ingredientDeletionIsLoading ? (
                                          <svg
                                            className="animate-spin h-5 w-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <circle
                                              className="opacity-25"
                                              cx="12"
                                              cy="12"
                                              r="10"
                                              stroke="currentColor"
                                              strokeWidth="4"
                                            ></circle>
                                            <path
                                              className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                          </svg>
                                        ) : (
                                          <FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
                                        )}
                                      </button>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                <>
                                  {languages.map((lang) => (
                                    <td key={lang.code} className="px-4 py-1 border-b border-gray-200 text-center w-2/12">
                                      {highlightText(getTranslation(lang.code), debouncedSearch)}
                                    </td>
                                  ))}
                                  <td className="px-4 py-1 border-b border-gray-200 text-center w-2/12">
                                    <div className="flex justify-center space-x-2">
                                      <button
                                        className="green-background hover:bg-green-600 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={() => startEditing(ingredient)}
                                      >
                                        <FiEdit2 className="w-4 h-4" />
                                      </button>
                                      <button
                                        className="bg-red-600 hover:bg-red-500 text-white h-7 w-7 rounded-full flex items-center justify-center shadow-md transition transform hover:scale-105 focus:outline-none"
                                        onClick={() => handleDeleteIngredient(ingredient._id)}
                                        disabled={ingredientDeletionIsLoading}
                                        title="Elimina Ingrediente"
                                      >
                                        {ingredientDeletionIsLoading ? (
                                          <svg
                                            className="animate-spin h-5 w-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <circle
                                              className="opacity-25"
                                              cx="12"
                                              cy="12"
                                              r="10"
                                              stroke="currentColor"
                                              strokeWidth="4"
                                            ></circle>
                                            <path
                                              className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8v8H4z"
                                            ></path>
                                          </svg>
                                        ) : (
                                          <FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
                                        )}
                                      </button>
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>

            {/* Footer con Pulsanti Fissi */}
            <div className="p-4 border-t flex justify-center space-x-4">
              {isAdding ? (
                <>
                  <button
                    className="bg-gray-300 text-white px-4 py-2 rounded-full"
                  >
                    Aggiungi Nuovo Ingrediente
                  </button>
                </>
              ) : (
                <button
                  onClick={() => setIsAdding(true)}
                  className="green-background hover:bg-green-600 text-white px-4 py-2 rounded-full transition transform hover:scale-105 focus:outline-none"
                >
                  Aggiungi Nuovo Ingrediente
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateIngredient;
