import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyOwner, resendVerifyOwner } from '../redux/ownersSlice';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/modal/Buttons';

const VerifyPageOwner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Inizializza useNavigate

  const verified = useSelector((state) => state.owners.ownerVerify);
  const verifying = useSelector((state) => state.owners.isVerifing);
  const errorVerify = useSelector((state) => state.owners.errorVerify);
  const expiredEmail = useSelector((state) => state.owners.expiredEmail);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const cleanToken = token ? token.replace(/\"/g, '') : '';

  useEffect(() => {
    if (cleanToken) {
      dispatch(verifyOwner(cleanToken));
    }
  }, [dispatch, cleanToken]);

  const handleResendClick = () => {
    if (expiredEmail) {
      dispatch(resendVerifyOwner(expiredEmail));
    }
  };

  const handleGoToLogin = () => {
    navigate('/login'); // Naviga programmaticamente a /login
  };

  return (
    <>
      {verifying ? (
        <div className='flex flex-row justify-center'>
          <div className='font-primary text-xs'>Verifying your account..</div>
        </div>
      ) : verified ? (
        <div className='flex flex-col align-middle items-center mt-20'>
          <div className='font-primary font-light text-emerald-400 p-2 text-xs'>{verified.message}</div>
          <Button onClick={handleGoToLogin} className='' type='green'>Go to Login</Button>
        </div>
      ) : errorVerify && (
        <div className='flex flex-col align-middle items-center'>
          <div className='font-primary text-xs'>Verification failed</div>
          <div className='font-light text-red-600 p-2 text-xs'>{errorVerify}</div>
          {expiredEmail && (
            <button onClick={handleResendClick} className='font-primary w-60 mb-1 input rounded-lg green-background p-2 text-center text-black text-sm'>
              Resend Verification Email
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default VerifyPageOwner;
