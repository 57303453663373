// src/api.js
import axios from 'axios';
import eventEmitter from './eventEmitter';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
});

// Interceptor per aggiungere il token a ogni richiesta
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('userLoggedIn');
        if (token) {
            config.headers.Authorization = `${token}`;
        } else {
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor per gestire le risposte
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Emmetti un evento di logout
           eventEmitter.emit('logout');
        }
        return Promise.reject(error);
    }
);

export default api;
