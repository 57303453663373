import React, { useEffect, useState } from 'react';
import itaFlag from '../../asset/ita.jpg';
import fraFlag from '../../asset/fra.jpg';
import spaFlag from '../../asset/spa.jpg';
import engFlag from '../../asset/eng.jpg';
import deuFlag from '../../asset/deu.jpg';
import { FiEdit } from 'react-icons/fi';
import _ from 'lodash';
import Modal from './Modal'


const Translations = ({ languages, defaultLanguage, handleLanguageChange, inputTranslations, outputTranslations, setOutputTranslations }) => {

    const flagImages = {
        ita: itaFlag,
        fra: fraFlag,
        spa: spaFlag,
        eng: engFlag,
        deu: deuFlag
    };

    const [showCreateTranslations, setShowCreateTranslations] = useState(false);
    const [tempTranslations, setTempTranslations] = useState(inputTranslations);
    const [defaultTranslation, setDefaultTranslation] = useState(null); // Salva la traduzione di default

    const handleTranslations = () => {
        // Prima di aprire la scheda di modifica, salva la traduzione di default
        const defaultTrans = inputTranslations.find(lang => lang.language === defaultLanguage);
        setDefaultTranslation(defaultTrans);

        // Filtra fuori la traduzione di default dal set di modifiche temporanee
        const otherTranslations = inputTranslations.filter(lang => lang.language !== defaultLanguage);
        setTempTranslations(otherTranslations);

        setShowCreateTranslations(true);
    };

    const closeCreateTranslations = (e) => {
        e.preventDefault();
        setShowCreateTranslations(false);
    };

    const handleTranslationChange = (index, field, value) => {
        setTempTranslations(prevTranslations =>
            prevTranslations.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item
            )
        );
    };

    const handleSaveTranslations = (e) => {
        e.preventDefault();

        // Aggiungi la traduzione di default alla fine
        const updatedTranslations = [...tempTranslations, defaultTranslation];

        // Salva tutte le traduzioni, inclusa quella di default
        setOutputTranslations(updatedTranslations);
        setShowCreateTranslations(false);
    };

    // Imposta la lingua di default come Italiano al primo caricamento
    useEffect(() => {
        if (handleLanguageChange) {
            const event = { target: { value: defaultLanguage } };
            handleLanguageChange(event);
        }
    }, []);

    // Filtra le traduzioni escludendo la lingua di default per la visualizzazione principale
    const filteredTranslations = inputTranslations.filter(lang => lang.language !== defaultLanguage);

    return (
        <>
            <div className='text-start flex items-center mt-4'>
                {defaultLanguage && (
                    <div className='flex flex-row justify-between gap-2 items-center my-2'>
                        <h3 className='font-bold'>Lingua di default</h3>
                        <div className='p-2 w-16 rounded-lg flex flex-row justify-between items-center'>
                            <img className='w-10 h-10 rounded-full' src={flagImages[defaultLanguage]} alt={`${defaultLanguage} flag`} />
                        </div>
                    </div>
                )}
            </div>

            <div className='flex flex-row gap-2 items-center my-1 mt-6'>
                <h3 className='font-bold'>Traduzioni ({filteredTranslations.filter(translation => translation.name).length}/{filteredTranslations.length})</h3>
                <div className='flex flex-row gap-2 w-10'>
                    {defaultLanguage !== '' && (
                        <button className="p-1 text-black rounded-lg" onClick={handleTranslations}>
                            <FiEdit />
                        </button>
                    )}
                    {filteredTranslations.length > 0 && filteredTranslations.map((lang) =>
                        lang.name ? (
                            <img src={flagImages[lang.language]} alt={`${lang.language} flag`} className="w-20 rounded-lg" key={lang.language} />
                        ) : null
                    )}
                </div>
            </div>

            {showCreateTranslations && (
                <Modal
                    handleSubmit={(e) => handleSaveTranslations(e)}
                    closeFunction={(e) => closeCreateTranslations(e)}
                    title="Traduzioni Menu"
                >
                    <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        {tempTranslations.map((translation, index) => {
                            const languageMap = {
                                eng: 'Inglese',
                                deu: 'Tedesco',
                                fra: 'Francese',
                                spa: 'Spagnolo',
                            };

                            return (
                                <div key={translation.language} className='bg-white shadow-lg rounded-lg p-4 flex flex-col items-center'>
                                    <div className='w-full flex justify-between items-center mb-4'>
                                        <img
                                            src={flagImages[translation.language]}
                                            alt={`${translation.language} flag`}
                                            className="w-10 h-10 rounded-full"
                                        />
                                        <span className="text-lg font-bold">{languageMap[translation.language]}</span>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Nome"
                                        className="border rounded-lg border-gray-300 p-3 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        value={translation.name}
                                        onChange={e => handleTranslationChange(index, 'name', e.target.value)}
                                    />
                                    <textarea
                                        placeholder="Descrizione"
                                        className="border rounded-lg border-gray-300 p-3 w-full mt-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                        value={translation.description}
                                        onChange={e => handleTranslationChange(index, 'description', e.target.value)}
                                        rows="4"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Modal>
            )}  
        </>
    );
};

export default Translations;
