import React, { useState } from 'react';
import { motion } from 'framer-motion'; // Importa Framer Motion
import Button from '../components/modal/Buttons';
import { useNavigate } from 'react-router-dom';
import SignUpModal from '../components/SignUpModal';
import nemuPosterIntroVideo from '../asset/Intro.png';
import TimeLine from '../components/TimeLine';
import nemuPosterMenuVideo from '../asset/Restaurant.png';
import nemuPosterConfVideo from '../asset/Cliente.jpg';
import { FaPizzaSlice, FaUtensils, FaBeer, FaCoffee } from 'react-icons/fa';
import QRCode from 'react-qr-code';

const logoNemu = require('../asset/logo.png');

const italianProvinces = [
    "Agrigento", "Alessandria", "Ancona", "Aosta", "Arezzo", "Ascoli Piceno", "Asti",
    "Avellino", "Bari", "Barletta-Andria-Trani", "Belluno", "Benevento", "Bergamo",
    "Biella", "Bologna", "Bolzano", "Brescia", "Brindisi", "Cagliari", "Caltanissetta",
    "Campobasso", "Caserta", "Catania", "Catanzaro", "Chieti", "Como", "Cosenza",
    "Cremona", "Crotone", "Cuneo", "Enna", "Fermo", "Ferrara", "Firenze", "Foggia",
    "Forlì-Cesena", "Frosinone", "Genova", "Gorizia", "Grosseto", "Imperia", "Isernia",
    "L'Aquila", "La Spezia", "Latina", "Lecce", "Lecco", "Livorno", "Lodi", "Lucca",
    "Macerata", "Mantova", "Massa-Carrara", "Matera", "Messina", "Milano", "Modena",
    "Monza e Brianza", "Napoli", "Novara", "Nuoro", "Oristano", "Padova", "Palermo",
    "Parma", "Pavia", "Perugia", "Pesaro e Urbino", "Pescara", "Piacenza", "Pisa",
    "Pistoia", "Pordenone", "Potenza", "Prato", "Ragusa", "Ravenna", "Reggio Calabria",
    "Reggio Emilia", "Rieti", "Rimini", "Roma", "Rovigo", "Salerno", "Sassari", "Savona",
    "Siena", "Siracusa", "Sondrio", "Sud Sardegna", "Taranto", "Teramo", "Terni", "Torino",
    "Trapani", "Trento", "Treviso", "Trieste", "Udine", "Varese", "Venezia", "Verbano-Cusio-Ossola",
    "Vercelli", "Verona", "Vibo Valentia", "Vicenza", "Viterbo"
];

const HomePage = () => {
    const [isEligible, setIsEligible] = useState(false);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [signUp, setSignUp] = useState(false);
    const navigate = useNavigate();

    const [showRegistration, setShowRegistration] = useState(false);
    const restaurantUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f7708d31f5a2be20467';
    const pizzeriaUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/67082f6f08d31f5a2be2036e';
    const barUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/671ec0082be7311e26ab64ef';
    const pubUrl = 'https://menu.nemu-app.com/menu/6707a75908d31f5a2be1f262/671ebff82be7311e26ab62d9';

    const handleRegisterClick = () => {
        setShowRegistration(true); // Se desideri aprire il modal
    };

    const handleChangeRegistration = () => {
        setShowRegistration(!showRegistration);
    };

    const scrollToPromo = () => {
        const element = document.getElementById('configuratore');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const scrollToRoadmap = () => {
        const element = document.getElementById('roadmap');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div>
            {/* Hero Section */}
            <section
                className="relative bg-cover bg-center w-full h-screen flex items-center justify-center"
                style={{ backgroundImage: `url('https://images.nemu-app.com/your-background-image.jpg')` }} // Sostituisci con l'URL della tua immagine di sfondo
            >
                <div className="absolute inset-0 green-background "></div> {/* Overlay verde scuro */}
                <motion.div
                    className="relative z-10 text-center text-white px-4 mb-20"
                    initial={{ opacity: 0, y: -200 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <div className='flex flex-col md:flex-row justify-center items-center mb-4'>
                        <h1 className="text-5xl md:text-6xl font-bold whitespace-nowrap">Benvenuto su</h1>
                        <img src={logoNemu} className="h-10 md:h-12 mt-4 md:mt-0 md:ml-4" alt="Logo Nemu" />
                    </div>
                    <p className="text-xl md:text-2xl mb-8">Il tuo partner ideale per menu digitali interattivi</p>
                    <div className="flex justify-center gap-4">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition"
                            onClick={scrollToPromo}
                        >
                            Scopri di più
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-transparent border-2 border-white text-white py-3 px-6 rounded-full hover:bg-white hover:text-green-500 transition"
                            onClick={handleRegisterClick}
                        >
                            Registrati
                        </motion.button>
                    </div>
                </motion.div>
            </section>


            {/* Contenitore Principale */}
            <div className=" mx-auto p-4 md:p-2 mt-8  green-background-text max-w-6xl">
                <main>

                    <section id="configuratore" className="border border-gray-300 p-10 rounded-2xl shadow-xl scroll-mt-20 flex flex-col md:flex-row items-center gap-8 mb-16 mt-12">
                        <div className="md:w-1/2">
                            <video
                                className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                                controls
                                poster={nemuPosterIntroVideo}
                            >
                                <source src={'https://images.nemu-app.com/nemu_intro_v3.mp4'} type="video/mp4" />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>
                        <div className="md:w-1/2">
                            <h2 className="text-3xl font-extrabold mb-6">Il Menu Digitale per Ristoranti sostenibile, economico ed efficiente</h2>
                            <p className="text-lg mb-4">
                                Dì addio alla stampa e ai costi aggiuntivi: con nemù, aggiorni il menu in tempo reale, migliorando l’efficienza e offrendo ai clienti una navigazione intuitiva grazie ai filtri avanzati per ingredienti e allergeni.
                            </p>
                            <p className="text-lg mt-4">
                                Nel 2025, nemù introdurrà nuove funzionalità per la gestione degli ordini, delivery, pagamenti in-app e molto altro.{' '}
                                <span
                                    onClick={scrollToRoadmap}
                                    className="text-green-500 cursor-pointer underline"
                                >
                                    Consulta la Roadmap completa
                                </span>.
                            </p>
                            <p className="text-lg mt-4">
                                <span
                                    onClick={handleRegisterClick}
                                    className="text-green-500 cursor-pointer underline"
                                >
                                    Contattaci oggi
                                </span>{' '}
                                per partecipare alla fase di test e ottenere nemù <b>gratuitamente per sempre</b>!
                            </p>

                        </div>
                    </section>

                    {/* Menu di esempio */}
                    <section className="p-4 border rounded-2xl shadow-xl flex flex-col items-center gap-2 mb-16">
                        <h3 className="text-3xl font-extrabold text-center mb-6">Menu di esempio</h3>
                        <div className="flex justify-start xlg:justify-center overflow-x-scroll space-x-3 w-full flex-nowrap">
                            {/* Pizzeria */}
                            <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl text-center">
                                <div className="flex flex-row items-center">
                                    <FaPizzaSlice size={40} className="mb-4 text-green-500 mr-4" />
                                    <h4 className="text-3xl font-semibold mb-4 text-green-500">Pizzeria</h4>
                                </div>
                                <QRCode value={pizzeriaUrl} size={180} />
                                <a href={pizzeriaUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">
                                    Vai al menu
                                </a>
                            </div>

                            {/* Ristorante */}
                            <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl text-center">
                                <div className="flex flex-row items-center">
                                    <FaUtensils size={40} className="mb-4 text-green-500 mr-4" />
                                    <h4 className="text-3xl font-semibold mb-4 text-green-500">Ristorante</h4>
                                </div>
                                <QRCode value={restaurantUrl} size={180} />
                                <a href={restaurantUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">
                                    Vai al menu
                                </a>
                            </div>

                            {/* Pub */}
                            <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl text-center">
                                <div className="flex flex-row items-center">
                                    <FaBeer size={40} className="mb-4 text-green-500 mr-4" />
                                    <h4 className="text-3xl font-semibold mb-4 text-green-500">Pub</h4>
                                </div>
                                <QRCode value={pubUrl} size={180} />
                                <a href={pubUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">
                                    Vai al menu
                                </a>
                            </div>

                            {/* Bar */}
                            <div className="flex-shrink-0 w-56 flex flex-col items-center p-2 bg-white border border-gray-300 rounded-2xl text-center">
                                <div className="flex flex-row items-center">
                                    <FaCoffee size={40} className="mb-4 text-green-500 mr-4" />
                                    <h4 className="text-3xl font-semibold mb-4 text-green-500">Bar</h4>
                                </div>
                                <QRCode value={barUrl} size={180} />
                                <a href={barUrl} target="_blank" rel="noopener noreferrer" className="mt-6 bg-green-500 text-white py-3 px-6 rounded-full hover:bg-green-600 transition">
                                    Vai al menu
                                </a>
                            </div>
                        </div>
                    </section>


                    {/* Prima sezione principale */}
                    <section  className="border border-gray-300 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16 mt-12">
                        <div className="md:w-1/2">
                            <h2 className="text-3xl font-extrabold mb-6">Personalizza il tuo menu con un click</h2>
                            <ul className="list-disc pl-5 text-lg text-gray-600">
                                <li className="mb-3">Menu digitale con interfaccia intuitiva e facile da usare</li>
                                <li className="mb-3">Creazione e aggiornamento del menu in tempo reale</li>
                                <li className="mb-3">Gestione multilingue</li>
                                <li className="mb-3">Informazioni su ingredienti e allergeni</li>
                                <li>Gratuito a vita per i primi ristoranti che aderiranno</li>
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <video
                                className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                                controls
                                poster={nemuPosterMenuVideo}
                            >
                                <source src={'https://images.nemu-app.com/nemu_demo_configuratore_v3.mp4'} type="video/mp4" />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>
                    </section>

                    {/* Seconda sezione */}
                    <section className="border border-gray-300 p-10 rounded-2xl shadow-xl flex flex-col md:flex-row items-center gap-8 mb-16">
                        <div className="md:w-1/2">
                            <video
                                className="w-full rounded-2xl shadow-lg transform hover:scale-105 transition duration-300"
                                controls
                                poster={nemuPosterConfVideo}
                            >
                                <source src={'https://images.nemu-app.com/nemu_demo_menu_v2.mp4'} type="video/mp4" />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>
                        <div className="md:w-1/2">
                            <h3 className="text-3xl font-extrabold mb-6">Il menu interattivo di nemù trasforma l'esperienza del cliente</h3>
                            <ul className="list-disc pl-5 text-lg text-gray-600">
                                <li className="mb-3">Filtri personalizzati per ingredienti e allergeni</li>
                                <li className="mb-3">Interfaccia utente coinvolgente e facile da navigare</li>
                                <li>Accesso immediato a informazioni dettagliate sui piatti</li>
                            </ul>
                        </div>
                    </section>

                    {/* Terza sezione (senza video) */}
                    <section className="border border-gray-300 p-10 rounded-2xl shadow-xl mb-16">
                        <h3 className="text-3xl font-extrabold mb-6">Unisciti alla nostra piattaforma e ottieni un menu digitale con QR Code</h3>
                        <p className="text-lg mb-4 font-bold">Come funziona?</p>
                        <ul className="list-disc pl-5 text-lg mb-8">
                            <li className="mb-3">Contattaci per aderire al nostro progamma di test e ricevi le credenziali di accesso</li>
                            <li className="mb-3">Inserisci i dettagli del tuo menu, inclusi ingredienti e allergeni</li>
                            <li>Genera automaticamente il menu digitale con QR Code da utilizzare nel tuo ristorante</li>

                        </ul>
                        <div className="text-lg font-semibold mb-6 flex flex-row items-center">
                            <p>
                                I ristoranti selezionati per la fase di test del servizio potranno usufruire di tutti i nostri servizi gratuitamente e per sempre.
                            </p>

                        </div>

                        <div className=''>
                            <Button type="green" onClick={handleRegisterClick}>
                                Registrati
                            </Button>
                        </div>
                        <p className="text-lg  mb-6"></p>

                        <div className="flex gap-4">

                        </div>
                    </section>

                    <section id="roadmap" className="">
                        <h3 className="text-3xl font-extrabold mb-6">Roadmap</h3>
                        <h5 className="text-lg green-background-text mb-4">Ecco l'elenco dei servizi che saranno rilasciati nel corso del 2025</h5>
                        <TimeLine />
                    </section>

                    {showRegistration && (
                        <SignUpModal
                            showSignUpModal={showRegistration}
                            setShowSignUpModal={handleChangeRegistration}
                        />
                    )}




                </main>
            </div>
        </div>
    );
};

export default HomePage;
