import {configureStore} from '@reduxjs/toolkit';
import loginReducer, { logout } from './loginsSlice';
import ownerReducer from './ownersSlice';
import menusReducer from './menusSlice';
import ingredientReducer from './ingredientsSlice';
import eventEmitter from '../eventEmitter';

const store = configureStore({
    reducer: {
      logins: loginReducer,
      owners: ownerReducer,
      menus: menusReducer,
      ingredients: ingredientReducer
    },
  });

  eventEmitter.on('logout', () => {
    store.dispatch(logout());
    window.location.href = '/login'; // Reindirizza alla pagina di login
});

export default store;
