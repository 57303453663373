// Buttons.js
import React from 'react';

const Buttons = ({ children, type, onClick, className }) => {
  let baseClasses =
    'px-3 py-2 rounded-full shadow-md transition transform hover:scale-105 focus:outline-none flex-shrink-0';

  let typeClasses = '';
  let contentClasses = '';
  let sizeClasses = '';

  switch (type) {
    case 'green':
      typeClasses = 'green-background hover:bg-green-600 text-white text-base';
      break;
    case 'red':
      typeClasses = 'bg-red-700 hover:bg-red-500 text-white text-base';
      break;
    case 'green_icon':
      typeClasses = 'green-background hover:bg-green-600 text-white';
      sizeClasses = 'h-9 w-9 sm:w-auto sm:px-3';
      contentClasses = 'flex items-center justify-center';
      break;
    case 'red_icon':
      typeClasses = 'bg-red-700 hover:bg-red-500 text-white';
      sizeClasses = 'h-9 w-9 sm:w-auto sm:px-3';
      contentClasses = 'flex items-center justify-center';
      break;
    default:
      typeClasses = '';
  }

  return (
    <button
      className={`${typeClasses} ${baseClasses} ${sizeClasses} ${contentClasses} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Buttons;
