import React, { useState, useEffect } from 'react';
import ImageCropper from './ImageCropper';

const ImageSection = ({
  children,
  inputImage,
  handleRemoveImage,
  setUploadedFileName,
  aspect,
  maxSizeMB,
  maxWidthOrHeight,
}) => {
  const [uploadMessage, setUploadMessage] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [imagePreview, setImagePreview] = useState(''); // Immagine confermata
  const [selectedImage, setSelectedImage] = useState(''); // Immagine selezionata per il ritaglio
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [imageError, setImageError] = useState(false); // Stato per l'errore di caricamento dell'immagine

  const apiImages = process.env.REACT_APP_IMG

  const handleAddImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsCropperOpen(true);
      setImageError(false); // Resetta lo stato dell'errore
      e.target.value = ''; // Resetta il file input
    }
  };

  const handleCropComplete = async (croppedImageUrl) => {
    try {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append('menu', blob);

      // Upload dell'immagine al backend
      const uploadResponse = await fetch(
        `${process.env.REACT_APP_SERVER_BASE_URL}/upload-image`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (uploadResponse.ok) {
        const responseData = await uploadResponse.json();
        const uploadedFileName = responseData.url.split('/').pop();
        setUploadedFileName(uploadedFileName);
        setUploadMessage(true);
        setImagePreview(croppedImageUrl); // Aggiorna l'anteprima con l'immagine ritagliata
        setIsCropperOpen(false);
        setSelectedImage(''); // Resetta l'immagine selezionata
        setImageError(false); // Resetta lo stato dell'errore
      } else {
        setUploadError(true);
        console.error('Error uploading file:', uploadResponse);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(true);
    }
  };

  useEffect(() => {
    if (inputImage) {
      const imageUrl = `${apiImages}/${inputImage}`;
      setImagePreview(imageUrl);
      setImageError(false); // Resetta lo stato dell'errore
    } else {
      setImagePreview('');
      setImageError(false); // Resetta lo stato dell'errore
    }
  }, [inputImage]);

  const handleRemoveImageAndPreview = () => {
    setImagePreview('');
    setImageError(false); // Resetta lo stato dell'errore
    handleRemoveImage();
  };

  const handleCancelCrop = () => {
    setIsCropperOpen(false);
    setSelectedImage(''); // Resetta l'immagine selezionata
  };

  return (
    <>
      <div className="text-left w-full mt-4">
        <span className="font-bold">{children || 'Immagine'}</span>
      </div>

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        className="w-full input rounded-lg border-gray-200 bg-white border p-2 text-black text-sm"
        onChange={handleAddImage}
      />

      {imagePreview && !isCropperOpen && !imageError && (
        <div className="relative flex mt-6">
          <div className="relative">
            <img
              src={imagePreview}
              alt="Anteprima"
              className="w-96 rounded-lg cursor-pointer"
              onClick={() => setIsCropperOpen(true)}
              onLoad={() => setImageError(false)}
              onError={() => setImageError(true)}
            />
            <button
              onClick={handleRemoveImageAndPreview}
              className="absolute top-0 right-0 font-primary bg-red-500/70 text-white rounded-full w-6 h-6 p-1 text-xs transform translate-x-1/2 -translate-y-1/2"
            >
              X
            </button>
          </div>
        </div>
      )}

      {isCropperOpen && (
        <ImageCropper
          imgSrc={selectedImage}
          onCropComplete={handleCropComplete}
          onCancel={handleCancelCrop}
          aspect={aspect || 2 / 1}
          maxSizeMB={maxSizeMB || 0.2}
          maxWidthOrHeight={maxWidthOrHeight || 1920}
        />
      )}

      {uploadMessage && (
        <span className="font-light text-sm text-emerald-400 ">Upload Success</span>
      )}
      {uploadError && (
        <span className="font-light text-sm text-red-600 ">Upload Error</span>
      )}
    </>
  );
};

export default ImageSection;
