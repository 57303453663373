// src/pages/Dashboard.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOwnerById } from '../redux/ownersSlice';
import { jwtDecode } from 'jwt-decode'; // Assicurati di usare l'importazione corretta
import MenuConfiguratorContainer from '../components/MenuConfiguratorContainer';
import { logout } from '../redux/loginsSlice'; // Importa l'azione di logout

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("userLoggedIn"); // Rimosso JSON.parse

    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Usa la funzione corretta
        const currentTime = Date.now() / 1000; // Tempo attuale in secondi

        if (decodedToken.exp < currentTime) {
          // Token scaduto
          dispatch(logout());
          // Optionally, you can navigate to the login page here
        } else {
          // Token valido, procedi con il fetch dei dati
          dispatch(getOwnerById(decodedToken.owner_id));
        }
      } catch (error) {
        // Token non valido
        console.error('Invalid token:', error);
        dispatch(logout());
        // Optionally, navigate to the login page
      }
    } else {
      // Nessun token presente, puoi navigare alla pagina di login
      dispatch(logout());
      // Optionally, navigate to the login page
    }
  }, [dispatch]);

  const owner = useSelector((state) => state.owners.ownerLogged?.owner);

  return (
    <>
      <MenuConfiguratorContainer />
      {/* Puoi aggiungere ulteriori componenti o dati basati su 'owner' qui */}
    </>
  );
};

export default Dashboard;
