// Modal.jsx
import React from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import Button from './Buttons';

const Modal = ({
  children,
  closeFunction,
  undoFunction,
  deleteFunction,
  handleSubmit,
  title,
  success,
  successMessage,
  error,
  errorMessage,
  isLoading,
  loading
}) => {


  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-90 flex items-center justify-center z-50">
      <div className='fixed top-0 font-primary text-black bg-white border lg:w-8/12 h-[85vh] w-11/12 rounded-lg pb-5 flex flex-col overflow-hidden ' style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}>

        {/* Header */}
        <div className='flex justify-between items-center flex-shrink-0 green-background text-white p-4 rounded-t-lg'>
          <h1 className="font-primary font-bold text-2xl">{title}</h1>
          <XMarkIcon className="w-8 h-8 cursor-pointer text-white" onClick={closeFunction} />
        </div>

        {/* Body: Scrollable Content */}
        <div className='p-5 flex-grow overflow-y-auto'>
          {children}
        </div>

        {/* Buttons and Feedback */}
        <div className='flex flex-col items-center mt-4 mb-4'>
          <div className='flex flex-row space-x-2'>
            {undoFunction && (
              <button
                className="mt-2 px-4 py-2 font-primary w-40 rounded-lg bg-black text-center  text-white text-sm"
                onClick={undoFunction}
              >
                Annulla
              </button>
            )}

            {handleSubmit && (
              <Button
                type="green"
                onClick={handleSubmit}
              >
                Salva
              </Button>
            )}

            {deleteFunction && (
              <Button
                type="red"
                onClick={deleteFunction}
              >
                Elimina
              </Button>
            )}
          </div>

          {/* Loader */}
          {(isLoading && loading) && (
            <div className='flex flex-row justify-center gap-2 pt-2 my-4'>
              <div className='custom-loader'></div>
            </div>
          )}

          {/* Success Message */}
          {success && successMessage && (
            <div className='flex flex-row justify-center gap-2 font-light text-emerald-400 p-2 text-xs my-4'>
              {successMessage}
            </div>
          )}

          {/* Error Message */}
          {error && errorMessage && (
            <div className='flex flex-row justify-center gap-2 font-light text-red-600 p-2 text-xs my-4'>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
