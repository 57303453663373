import React, { useRef } from 'react';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';
import Buttons from './modal/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../asset/logo_green.png'; // Assicurati che il percorso sia corretto

const QRCodeModal = ({ isOpen, onRequestClose, url }) => {
    Modal.setAppElement('#root');
    
    const qrCodeRef = useRef(null);

    const generateQRCodeImage = async () => {
        const canvas = await html2canvas(qrCodeRef.current, { scale: 4 });
        return canvas.toDataURL('image/png');
    };

    const printPDF = async () => {
        const imgData = await generateQRCodeImage();
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();

        // Aggiungi il logo mantenendo le proporzioni
        const img = new Image();
        img.src = logo;
        img.onload = () => {
            const imgAspect = img.width / img.height;
            const logoHeight = 20; // altezza del logo in mm
            const logoWidth = logoHeight * imgAspect; // larghezza del logo mantenendo le proporzioni
            pdf.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, 10, logoWidth, logoHeight);

            // Aggiungi il testo suddiviso in più righe
            pdf.setFontSize(12);
            const textLines = pdf.splitTextToSize(`Ecco il QR Code che puoi stampare e inserire nel tuo ristorante. ${url}`, pageWidth - 20);
            pdf.text(textLines, pageWidth / 2, 40, { align: 'center' });

            const qrSizes = [
                { label: '3x3 cm', size: 30 },
                { label: '5x5 cm', size: 50 },
                { label: '10x10 cm', size: 100 }
            ];

            let currentY = 60 + (textLines.length * 5); // Aggiungi spazio in base al numero di righe del testo

            qrSizes.forEach((qr, index) => {
                const x = (pageWidth - qr.size) / 2;
                pdf.addImage(imgData, 'PNG', x, currentY, qr.size, qr.size);
                pdf.text(qr.label, pageWidth / 2, currentY + qr.size + 5, { align: 'center' }); // Testo sotto il QR code centrato
                currentY += qr.size + 20; // Aggiorna la posizione Y per il prossimo QR code
            });

            pdf.save('qrcode.pdf');
        };
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="QRCode Modal" className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded shadow-lg w-96 border border-gray-100 overflow-y-auto max-h-full relative">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-bold">QR Code</h2>
                    <button onClick={onRequestClose} className="text-gray-500 hover:text-gray-700 text-2xl">
                        &times;
                    </button>
                </div>
                <div id="qrcode-container" className="flex flex-col items-center m-0">
                    <div ref={qrCodeRef} style={{ margin: '0', padding: '10px', backgroundColor: 'white' }}>
                        <QRCode value={url} size={300} />
                    </div>
                </div>
                <div className="text-center">
                    <p className="break-all">{url}</p>
                </div>
                <div className="mt-2 flex justify-center space-x-2">
                    <Buttons type="green" onClick={printPDF}>Stampa PDF</Buttons>
                </div>
            </div>
        </Modal>
    );
};

export default QRCodeModal;
