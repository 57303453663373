// src/slices/loginsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode'; // Import Corretto
import api from '../api'; // Importa l'istanza personalizzata

const apiUrlLogin = `/login`;

export const login = createAsyncThunk('logins/login', async(loginData, {rejectWithValue})=> {
    try {
        const response = await api.post(apiUrlLogin, loginData);
        const {data} = response;
        localStorage.setItem('userLoggedIn', data.token); // Rimuovi JSON.stringify
        return data
    } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            return rejectWithValue(error.response.data.message)
          } else {
              throw error;
          }
    }
})

const loginsSlice = createSlice({
    name:'logins',
    initialState:{
        isLoading: true, // Corretto
        error: null,
        successMessage: null,
        userId: null,
        isLogged: false
    }, 
    reducers:{
        logout: (state) => {
            state.userId = null;
            state.error = null;
            state.successMessage = null;
            state.isLogged = false
            localStorage.removeItem('userLoggedIn');
        },
    }, 
    extraReducers: (builder) => {
        builder
        .addCase(login.pending, (state, action) =>{
            state.isLoading = true; // Corretto
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false; // Corretto
            state.error = action.payload
        })
        .addCase(login.fulfilled, (state, action) =>{
            state.successMessage = action.payload.message;
            state.isLoading = false; // Corretto
            state.isLogged = true;
            
            const token = localStorage.getItem('userLoggedIn'); // Rimuovi JSON.parse
            if (token) {
                const decodedToken = jwtDecode(token);
                state.userId = decodedToken.owner_id;

                // Verifica la scadenza del token
                const currentTime = Date.now() / 1000; // in secondi
                if (decodedToken.exp < currentTime) {
                    state.isLogged = false;
                    state.userId = null;
                    localStorage.removeItem('userLoggedIn');
                    // Potresti voler dispatchare un'azione di logout qui
                }
            }
        })
    }
})

export const { logout } = loginsSlice.actions;
export default loginsSlice.reducer;
